import React, { Component } from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import MyCollection from './myCollection'
import MyPost from './myPost'
import './myCollectionAndPostPage.less'
import {  Col, Row,Tabs } from 'antd'

const { TabPane } = Tabs;
export default class myCollectionAndPostPage extends Component {
  constructor(props){
    super(props)
    this.state=({
      defaultActiveKey:this.props.location.state==undefined?"1":this.props.location.state.tab
    })
  }
  render() {
    return (
      <div className='my'>
          <Header></Header>
          <div className='my-center'>
            <Tabs defaultActiveKey={this.state.defaultActiveKey}>
              <TabPane
                tab={
                  <span>
                    我的投递
                  </span>
                }
                key="1"
              >
                <MyPost></MyPost>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    我的收藏
                  </span>
                }
                key="2"
              >
                <MyCollection></MyCollection>
              </TabPane>
            </Tabs>
          </div>
          <Footer></Footer>
      </div>
    )
  }
}
