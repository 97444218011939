import {Col, Row, Carousel} from 'antd'
import React, {Component} from 'react'
import './companyDetailIntroduction.less'

export default class companyDetailIntroduction extends React.PureComponent {
	constructor(props) {
		super(props)

	}

	onChange(a, b, c) {
		// console.log(a, b, c);
	}

	componentDidMount() {
	}

	render() {
		var environment = this.props.environment
		return (
			<div className='company-detail-introduction'>
				<Row>
					<Col className='cdi-first-col'>
						<div className='cdi-box'>
							<Row className='title-head'>
								<div className='title-logo'>
								</div>
								<div className='title-character'>
									公司介绍
								</div>
							</Row>
							<div className='title-detail'>
								{this.props.description}
								{/* HHHHHBBcdcdcddcdcdcdcdcdcdcdcdcdcdcdcdcdcdcdswwregeftBBBBBBBBBHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHTUIRLTEEBJRGTDOGBBRODJLG.KH.BI4KET,HVBNETFBMVETRSBJ */}
							</div>
							<Row className='title-head'>
								<div className='title-logo'>
								</div>
								<div className='title-character'>
									公司地点
								</div>
							</Row>
							<div className='title-detail'>
								<div className='img-locate'><img className='locate-icon' src={require('./../../assets/locate-icon.png')}
								                                 alt=""/></div>
								{this.props.address.address}
							</div>
						</div>
					</Col>
					<Col className='cdi-second-col'>
						<Row className='title-head'>
							<div className='title-logo'>
							</div>
							<div className='title-character'>
								公司相册
							</div>
						</Row>
						<div className='carousel'>
							<Carousel>
								{
									this.props.environment == null || this.props.environment == "" ?
										(<div>暂无图片</div>)
										:
										this.props.environment.map((e) => {
											return (
												<img className='carousel-img' src={e}></img>
											)
										})
								}
							</Carousel>
						</div>
					</Col>
				</Row>
			</div>
		)
	}
}
