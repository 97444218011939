import React, {Component} from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import CompanyDetailIntroduction from './companyDetailIntroduction'
import Recruitment from './recruitment'
import './companyDetail.less'
import {Col, Row, Tabs} from 'antd'
import qs from 'qs';
import http from '../../http'

const {TabPane} = Tabs;
export default class companyDetail extends Component {
	constructor(props) {
		super(props)
		const {company} = qs.parse(props.location.search.replace(/^\?/, ''))
		this.state = ({
			defaultActiveKey: this.props.location.state == undefined ? "1" : this.props.location.state.tab,
			companyID: company,
			companyDetail: {
				scale: {
					name: ""
				},
				industry: [
					{
						name: ""
					}
				],
				address: {
					city: ""
				},
				position: [],
				description: "",
				environment: "",
			}
		})
		this.getCompanyDetail()
	}

	getCompanyDetail() {
		http.getCompanyDetail(this.state.companyID).then((res) => {
			if (res.result == 1) {
				this.setState({
					companyDetail: res.data
				})
			}

		})
	}

	componentDidMount() {
		this.getCompanyDetail()
	}

	render() {
		const companyDetail = this.state.companyDetail
		return (
			<div id='cd'>
				<Header></Header>
				<div className='cd'>
					<div className='cd-center'>
						<div className='cd-header'>
							<Row justify='space-between'>
								<Col>
									<Row>
										<img className='cd-company-logo' src={companyDetail.logo}></img>
										<div className='cd-company-detail'>
											<div className='cd-job'>
												{companyDetail.name}
											</div>
											<div className='cd-box'></div>
											<div className='cd-detail'>
												{companyDetail.scale.name}&nbsp;&nbsp;|&nbsp;&nbsp;{companyDetail.industry.map((e, index) => {
												if (index == 0) {
													return e.name
												} else {
													return "、" + e.name
												}
											})}&nbsp;&nbsp;|&nbsp;&nbsp;{companyDetail.address.city}
											</div>
										</div>
									</Row>
								</Col>
								<Col>
									<Row>
										<div className='cd-job-number'>
											<div className='cd-number'>
												{companyDetail.position.length}
											</div>
											<div className='cd-box'>

											</div>
											<div className='cd-text'>
												在招职位
											</div>
										</div>
									</Row>
								</Col>
							</Row>
						</div>
						<div>
							<Tabs defaultActiveKey={this.state.defaultActiveKey}>
								<TabPane
									tab={
										<span>
                    公司介绍
                  </span>
									}
									key="1"
								>
									<CompanyDetailIntroduction
										environment={this.state.companyDetail.environment}
										address={this.state.companyDetail.address}
										description={this.state.companyDetail.description}></CompanyDetailIntroduction>
								</TabPane>
								<TabPane
									tab={
										<span>
                    招聘职位 <div className='badge'>{companyDetail.position.length}</div>
                  </span>
									}
									key="2"
								>
									<Recruitment position={this.state.companyDetail.position}></Recruitment>
								</TabPane>
							</Tabs>
						</div>
					</div>
				</div>
				<Footer></Footer>
			</div>
		)
	}
}
