import { Button } from 'antd'
import React, { Component } from 'react'
import Head from '../header/header'
import HomePage from '../homePage/homePage'
import Footer from '../footer/footer'
import SearchInput from '../searchInput/searchInput'
import JobCard from '../homePage/jobCard'
import CompanyCard from '../homePage/companyCard'
import './login.less'
import http from '../../http'

export default class login extends Component {
  constructor(props){
    super(props)
    this.state={
      positionList:[],
      CompanyList:[]
    }
    this.getPositionList()
    this.getCompanyList()
  }

  getPositionList(){
    var param={
      _b:1,
      _e:12
    }
    http.getHotPosition(param).then(res=>{
      if(res.result===1){
        this.setState({
          positionList:res.data
        })
      }
    })
  }

  getCompanyList(){
    var param={
      _b:1,
      _e:9
    }
    http.hotCompany(param).then(res=>{
      if(res.result===1){
        this.setState({
          CompanyList:res.data
        })
      }
    })
  }

  render() {
    return (
      <div className='login'>
        <Head></Head>
        <HomePage></HomePage>
        <div className='center'>
          <div className='search'>
            <SearchInput history={this.props.history}></SearchInput>
          </div>
          <div className='title-box'>
            <img src={require('./../../assets/new-job.png')} alt="" className='title'/>
          </div>
          <div className='job-box-1'>
            {
              this.state.positionList.map((e,index)=>{
                return (
                  <div key={e.id}>
                    <JobCard key={e.id} job={e}></JobCard>
                  </div>
                )
              })
            }
          </div>
          <div className='title-box'>
            <img src={require('./../../assets/company-recommend.png')} alt='' className='title'/>
          </div>
          <div className='job-box-2'>
          {
              this.state.CompanyList.map((e,index)=>{
                if(index<9){
                return (
                  <div key={index}>
                    <CompanyCard company={e}></CompanyCard>
                  </div>
                )
              }
              })
          }
          </div>
        </div>
        <div className=''> </div>
        <Footer></Footer>
      </div>
    )
  }
}
