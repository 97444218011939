import { Col, Pagination, Row } from 'antd'
import React, { Component } from 'react'
import RecruitmentCard from './recruitmentCard'
import VisitedJob from '../visitedJob/visitedJob'
import './recruitment.less'

export default class recruitment extends Component {
  constructor(props){
    super(props)
    this.state=({
        total:props.position.length,
        current:1,
        pageSize:10,
    })
  }
  //翻页或页数改变时回调函数
  changePage = (current) => {
    this.setState({ current: current })
  }
  render() {
    const position = this.props.position
    return (
      <div className='recruitment'>
          <Row>
              <Col className='r-first-col'>
                {
                      position.map((e,index)=>{
                        if(index>=this.state.pageSize * (this.state.current - 1)&&index<this.state.pageSize * this.state.current){
                          return (<div className='r-card'>
                              <RecruitmentCard job={e}></RecruitmentCard>
                            </div>)
                        }
                      })
                }
                {/* <div className='r-card'>
                  <RecruitmentCard></RecruitmentCard>
                </div>
                <div className='r-card'>
                  <RecruitmentCard></RecruitmentCard>
                </div>                
                <div className='r-card'>
                  <RecruitmentCard></RecruitmentCard>
                </div>                
                <div className='r-card'>
                  <RecruitmentCard></RecruitmentCard>
                </div> */}
                  <div className='page'>
                    <Pagination 
                    showQuickJumper 
                    current={this.state.current} 
                    total={this.state.total} 
                    showTotal={total => `共${this.state.total}条信息`} 
                    onChange={this.changePage} />
                  </div>
              </Col>
              <Col className='r-second-col'>
                <VisitedJob></VisitedJob>
              </Col>
          </Row>
      </div>
    )
  }
}
