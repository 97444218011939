import { Col, Row,Pagination } from 'antd'
import React, { Component } from 'react'
import VisitedJob from '../visitedJob/visitedJob'
import SearchPageJobCard from '../searchPage/searchPageJobCard'
import './myCollection.less'
import http from '../../http'

export default class myCollection extends Component {
  constructor(props){
    super(props)
    this.state=({
      collectionJobList:[],
      total:0,
      current:1,
      pageSize:10,
    })
    this.getCollectionJobList()
  }

  getCollectionJobList(){
    var param={
      _b: this.state.pageSize * (this.state.current - 1) + 1,
      _e: this.state.pageSize * this.state.current,
    }
    http.getJobCollection().then((res)=>{
      http.getJobCollectionList(param).then((res1)=>{
        this.setState({
          total:res.data,
          collectionJobList:res1.data
        })
      })
    })
  }

  changePage = (current) => {
    this.setState({ current: current }, () => {
        this.getCollectionJobList()
    })
  }
  render() {
    return (
      <div>
        <Row>
          <Col className='col'>
            {
              this.state.collectionJobList==null?
              (<div></div>)
              :
              (
              this.state.collectionJobList.map((e)=>{
                return (
                  <div className='mc-card' key={e.id}>
                    <SearchPageJobCard job={e} key={e.id} type='collection'></SearchPageJobCard>
                  </div>
                )
              })
              )
            }
            <div className='page'>
              <Pagination 
                    showQuickJumper 
                    current={this.state.current} 
                    total={this.state.total} 
                    showTotal={total => `共${this.state.total}条信息`} 
                    onChange={this.changePage} />
            </div>
            {/* <div className='mc-card'>
              <SearchPageJobCard type='collection'></SearchPageJobCard>
            </div>
            <div className='mc-card'>
              <SearchPageJobCard type='collection'></SearchPageJobCard>
            </div>
            <div className='mc-card'>
              <SearchPageJobCard type='collection'></SearchPageJobCard>
            </div>
            <div className='mc-card'>
              <SearchPageJobCard type='collection'></SearchPageJobCard>
            </div>
            <div className='mc-card'>
              <SearchPageJobCard type='collection'></SearchPageJobCard>
            </div>
            <div className='mc-card'>
              <SearchPageJobCard type='collection'></SearchPageJobCard>
            </div> */}
          </Col>
          <Col>
            <div className='mc-second-col'>
              <VisitedJob></VisitedJob>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
