import error from '../components/error/error'
import login from '../components/login/login'
import homePage from '../components/homePage/homePage'
import searchPage from '../components/searchPage/searchPage';
import companyDetail from '../components/companyDetail/companyDetail';
import JobDetail from '../components/jobDetail/jobDetail';
import myCollectionAndPostPage from '../components/myCollectionAndPost/myCollectionAndPostPage';
import personalInformation from '../components/personalInformation/personalInformation';

//auth  路由是否需要校验登录状态
var routerMap = [
    {
        path: "/login", name: "login", component: login
    },
    {
        path: "/searchPage",name:"searchPage",component:searchPage,auth:true
    },
    {
        path:"/companyDetail",name:"companyDetail",component:companyDetail,auth:true
    },
    {
        path:"/my",name:"my",component:myCollectionAndPostPage,auth:true
    },
    {
        path:"/imformation",name:"imformation",component:personalInformation,auth:true
    },
    {
        path:"/jobDetail",name:"jobDetail",component:JobDetail,auth:true
    },    
    {
        path: "/", exact: true,name: "firstPage", component: login, title: '首页',layout:true
    },
    {
        path: "/404", name: "404", component: error
    },

];
export default routerMap;