import React, {Component} from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
//import SearchInput from '../searchInput/searchInput'
import LoginGuideBox from './loginGuideBox'
import HotCompany from './hotCompany'
import CompanyCard from './searchPageCompanyCard'
import JobCard from './searchPageJobCard'
import './searchPage.less'
import {Col, Row, Pagination, message, Input, Button, Avatar, Cascader} from 'antd'
import {Link} from 'react-router-dom'
import http from '../../http'

//const {Search} = Input;
//const {Option} = Select;

// const options = [
// 	'杭州市',
// 	'苏州市'
// ];

export default class searchPage extends Component {
	constructor(props) {
		super(props)
		this.state = ({
			positionList: [],
			companyList: [],
			options: [],
			// props.location.state==undefined?JSON.parse(localStorage.getItem("companyList")):props.location.state.company,
			keyWords: props.location.state === undefined ? localStorage.getItem("keyWords") : props.location.state.keyWords,
			total: 0,
			current: 1,
			pageSize: 10,
			yearsList: [{id: 0, name: '经验不限'}],
			yearsID: 0,
			educationList: [{id: 0, name: '经验不限'}],
			educationID: 0,
			salaryList: [{id: 0, name: '经验不限'}],
			salaryID: 0,
			provinceInfo: [],
			cityInfo: [],
			defaultCity: props.location.state === undefined ? (localStorage.getItem("defaultCity") === '' ? [] : localStorage.getItem("defaultCity").split(',').map(item => {
				return Number(item)
			})) : props.location.state.defaultCity,
			city: props.location.state === undefined ? Number(localStorage.getItem("city")) : props.location.state.city,
			star: 0,
			apply: 0
		})
		
		this.getSearchPositionList()
		this.getSearchPositionTotal()
		this.getWorKYears()
		this.getEducation()
		this.getSalary()
		this.getAllCityData()
		this.getApply()
		this.getStar()
		this.Search()
	}

	getApply() {
		http.getJobApply().then((res) => {
			this.setState({
				apply: res.data
			})
		})
	}

	getStar() {
		http.getJobCollection().then((res) => {
			this.setState({
				star: res.data
			})
		})
	}

	//根据城市筛选
	getAllCityData() {
		http.getAddressInfo().then(res => {
			let options = [{
				value: 0,
				label: '不限',
				children: [{
					value: 0,
					label: '不限地区',
				}]
			}];
			res.data.map(province => {
				let children = [];
				province.sub.map(city => {
					children.push({
						value: city.id,
						label: city.name,
					})
				})
				options.push({
					value: province.id,
					label: province.name,
					children,
				})
			});
			this.setState({
				options
			})
		})
	}

	selectedCity(value) {
		if (value === undefined) {
			this.setState(state => {
				state.defaultCity = [];
				state.city = 0;
			})
		} else {
			this.setState(state => {
				state.city = value[value.length - 1];
				state.defaultCity = value;
			})
		}
	}

	// getProvinceInfo() {
	// 	http.getMapInfo({id: 1}).then(res => {
	// 		this.setState({
	// 			provinceInfo: res.data.sub
	// 		})
	// 	})
	// }
	// handleProvinceChange(value) {
	// 	http.getMapInfo({id: value}).then(res => {
	// 		this.setState({
	// 			cityInfo: res.data.sub
	// 		})
	// 		// setCityInfo(res.data.sub);
	// 	})
	//
	// }
	// handleCityChange(value) {
	// 	this.setState(state => {
	// 		state.city = value
	// 	})
	// }

	getWorKYears() {
		http.getWorkYear().then((res) => {
			this.setState({
				yearsList: this.state.yearsList.concat(res.data)
			})
		})
	}

	getEducation() {
		http.getEducation().then((res) => {
			this.setState({
				educationList: this.state.educationList.concat(res.data)
			})
		})
	}

	getSalary() {
		http.getSalary().then((res) => {
			this.setState({
				salaryList: this.state.salaryList.concat(res.data)
			})
		})
	}

	getSearchPositionList() {
		let params = {
			key_words: this.state.keyWords,
			// localStorage.getItem("keyWords"),
			_b: this.state.pageSize * (this.state.current - 1) + 1,
			_e: this.state.pageSize * this.state.current
		}
		if (this.state.yearsID !== 0) {
			params.work_years = this.state.yearsID
		}
		if (this.state.educationID !== 0) {
			params.education = this.state.educationID
		}
		if (this.state.salaryID !== 0) {
			params.salary = this.state.salaryID
		}

		http.getSearchPosition(params).then(res1 => {
			if (res1.result === 1) {
				this.setState({
					positionList: res1.data,
				})
			} else {
				message.error("网络错误")
			}
		})
	}

	Search() {
		let params = {
			_b: this.state.pageSize * (this.state.current - 1) + 1,
			_e: this.state.pageSize * this.state.current,
			// salary:44,
			// education:40,
			key_words: this.state.keyWords
		}
		let params2 = {
			key_words: this.state.keyWords
		}
		if (this.state.yearsID !== 0) {
			params.work_years = this.state.yearsID
		}
		if (this.state.educationID !== 0) {
			params.education = this.state.educationID
		}
		if (this.state.salaryID !== 0) {
			params.salary = this.state.salaryID
		}
		if (this.state.city !== 0) {
			params.city = this.state.city
			params2.city = this.state.city
		}

		localStorage.setItem("keyWords", this.state.keyWords);
		localStorage.setItem("city", this.state.city);
		localStorage.setItem("defaultCity", this.state.defaultCity);
		// console.log(this.state.city);
		// console.log(this.state.defaultCity);
		// console.log(this.state.keyWords);
		// console.log(localStorage.getItem("city"));
		// console.log(localStorage.getItem("defaultCity"));
		// console.log(localStorage.getItem("keyWords"));
		// http
		http.getSearchPosition(params).then(res1 => {
			if (res1.result === 1) {
				// message.success("成功")
				http.getSearchCompany(params2).then(res2 => {
					// localStorage.setItem("companyList",JSON.stringify(res2.data))
					this.setState({
						positionList: res1.data,
						companyList: res2.data
					})
				})
			} else {
				message.error("系统故障，请重试")
			}
		})
		this.getSearchPositionTotal()
	}

	getSearchPositionTotal() {
		let params2 = {
			key_words: this.state.keyWords
			// localStorage.getItem("keyWords")==null?"":localStorage.getItem("keyWords"),
		}
		if (this.state.yearsID !== 0) {
			params2.work_years = this.state.yearsID
		}
		if (this.state.educationID !== 0) {
			params2.education = this.state.educationID
		}
		if (this.state.salaryID !== 0) {
			params2.salary = this.state.salaryID
		}
		if (this.state.city !== 0) {
			params2.city = this.state.city
		}
		http.getSearchPositionTotal(params2).then(res2 => {
			if (res2.result === 1) {
				this.setState({
					total: res2.data
				})
			} else {
				message.error("网络错误")
			}
		})
	}

	//翻页或页数改变时回调函数
	changePage = (current) => {
		this.setState({current: current}, () => {
			this.getSearchPositionList()
		})
	}

	changeYears(e) {
		this.setState({
			yearsID: e.id
		}, () => {
			this.Search();
			this.getSearchPositionTotal()
		})
	}

	changeEducation(e) {
		this.setState({
			educationID: e.id
		}, () => {
			this.Search();
			this.getSearchPositionTotal()
		})
	}

	changeSalary(e) {
		this.setState({
			salaryID: e.id
		}, () => {
			this.Search();
			this.getSearchPositionTotal()
		})
	}

	render() {
		return (
			<div className='search-page'>
				<Header/>
				<div className='background'>
					<div className='center'>
						<Row>
							<Col className='first-col'>
								<div className='search-box'>
									<Input.Group size='large' compact>
										<div className={'search-left'} style={{borderRadius: '5px 0 0 5px'}}>
											<Cascader className={'search-city'} defaultValue={this.state.defaultCity}
											          options={this.state.options}
											          onChange={(e) => this.selectedCity(e)} placeholder="选择省市"
											          bordered={false} allowClear={false}/>
											<Input className={'search-input'} placeholder={this.state.keyWords === '' ? "搜索职位/公司" : ""}
											       value={this.state.keyWords} bordered={false}
											       onChange={e => {
												       this.setState({keyWords: e.target.value})
											       }}/>
										</div>
										<Button className={'search-button'} style={{borderRadius: '0 5px 5px 0'}} type="primary"
										        onClick={this.Search.bind(this)}>搜索</Button>
										{/* </Link> */}
									</Input.Group>
								</div>
								<div className='key-word-box'>
									<Row>
										<div className='tag'>
											<div className='title'>
												工作经验
											</div>
											{
												this.state.yearsList.map((e) => {
													return (
														<div className={this.state.yearsID === e.id ? "tag-name-active" : "tag-name"}
														     onClick={() => this.changeYears(e)} key={e.id}>
															{e.name}
														</div>
													)
												})
											}
										</div>
									</Row>
									<Row className='divide'>
										<div className='tag'>
											<div className='title'>
												要求学历
											</div>
											{
												this.state.educationList.map((e) => {
													return (
														<div className={this.state.educationID === e.id ? "tag-name-active" : "tag-name"}
														     onClick={() => this.changeEducation(e)} key={e.id}>
															{e.name}
														</div>
													)
												})
											}
										</div>
									</Row>
									<Row>
										<div className='tag'>
											<div className='title'>
												薪资范围
											</div>
											{
												this.state.salaryList.map((e) => {
													return (
														<div className={this.state.salaryID === e.id ? "tag-name-active" : "tag-name"}
														     onClick={() => this.changeSalary(e)} key={e.id}>
															{e.name}
														</div>
													)
												})
											}
										</div>
									</Row>
								</div>
								<div>
									{
										this.state.current === 1 ?
											(
												this.state.companyList != null ?
													(
														this.state.companyList.map((e, index) => {
															return (
																<div key={e.id} className='card'>
																	<CompanyCard company={e} history={this.props.history}/>
																</div>
															)
														})
													)
													:
													null
											)
											:
											null
									}
									{
										this.state.positionList.map((e, index) => {
											return (
												<div className='card' key={e.id}>
													<JobCard type='search' key={'job' + e.id} job={e}/>
												</div>
											)
										})
									}
								</div>
								<div className='page'>
									<Pagination
										showQuickJumper
										current={this.state.current}
										total={this.state.total}
										showTotal={total => `共${this.state.total}条信息`}
										onChange={this.changePage}/>
								</div>
							</Col>
							<Col className='second-col'>
								{
									!localStorage.getItem("token") ?
										(
											<div className='APP-box'>
												<div className='not-login'>
													<img className='login-icon' src={require('./../../assets/login-icon.png')} alt=""/>
													登录，查看更多职位
												</div>
											</div>
										)
										:
										(
											<div className='show'>
												<div className='APP-box-login'>
													<div className='login'>
														<img className='login-icon' src={require('./../../assets/app-icon.png')} alt=""/>
														用指南车APP，找工作快人一步！
													</div>
												</div>
												<div className='app-code-box'>
													<img className='app-code' src={require('./../../assets/app-code.png')} alt=""></img>
													<div className='text'>
														扫描二维码,<br/>下载指南车APP!
													</div>
												</div>
											</div>
										)
								}
								{
									!localStorage.getItem("token") ?
										(
											<div className='guide'>
												<Link to={{pathname: '/login'}}>
													<LoginGuideBox></LoginGuideBox>
												</Link>
											</div>
										)
										:
										(
											<div className="user-box">
												<div className='user'>
													<Row>
														<Col>
															<Avatar size={48} src={JSON.parse(localStorage.getItem("userInfo")).head_img}></Avatar>
														</Col>
														<Col justify="space-around">
															<Row>
																<div className='name'>
																	{JSON.parse(localStorage.getItem("userInfo")).name}
																</div>
															</Row>
															<Row>
																<Link to={{
																	pathname: "imformation",
																}}>
																	<div className='text'>
																		我的在线简历
																		<img className='right-icon' src={require('./../../assets/right-icon.png')} alt=""></img>
																	</div>
																</Link>
															</Row>
														</Col>
													</Row>
													<Row className='second-row' justify='space-around'>
														<Col>
															<Link to={{
																pathname: "my",
																state: {
																	tab: "1"
																}
															}}>
																<div style={{cursor: 'pointer'}}>
																	<div className='name'>
																		{this.state.apply}
																	</div>
																	<div className='text1'>
																		我的投递
																		<img className='right-icon' src={require('./../../assets/right-icon.png')} alt=""></img>
																	</div>
																</div>
															</Link>
														</Col>
														<Col>
															<div className='divide'>

															</div>
														</Col>
														<Col>
															<Link to={{
																pathname: "my",
																state: {
																	tab: "2"
																}
															}}>
																<div>
																	<div className='name' style={{cursor: 'pointer'}}>
																		{this.state.star}
																	</div>
																	<div className='text1'>
																		我的收藏
																		<img className='right-icon' src={require('./../../assets/right-icon.png')} alt=""></img>
																	</div>
																</div>
															</Link>
														</Col>
													</Row>
												</div>
											</div>
										)
								}
								<div>
									<HotCompany></HotCompany>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<Footer></Footer>
			</div>
		)
	}
}
