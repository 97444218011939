import React, { Component } from 'react'
import { Col, Modal, Row, Upload, Input, Button, Avatar, message, Form, Select, DatePicker, InputNumber } from 'antd'
import http from '../../http'
import FormItem from 'antd/lib/form/FormItem'
import common from '../../public/base'
import moment from 'moment'

const { RangePicker } = DatePicker;

const { TextArea } = Input;
export default class workEXP extends Component {
    formRef3 = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            confirmLoading: false,
            imformationModalVisible3: false,
            workList: [],
            workEXP: {
                id: "",
                name: "",
                position: "",
                description: "",
                start_time: 0,
                end_time: 0
            },
        }
        this.getWorkExperience()
    }

    imformationModalOK3() {
        this.setState({
            confirmLoading: true
        })
        var params = {}
        var flag = true
        if (this.state.workEXP.name != "") {
            params.name = this.state.workEXP.name
        } else {
            message.info("请填写公司名称")
            flag = false
        }
        if (this.state.workEXP.position != "") {
            params.position = this.state.workEXP.position
        } else {
            message.info("请填写职位名称")
            flag = false
        }
        if (this.state.workEXP.description != "") {
            params.description = this.state.workEXP.description
        } else {
            message.info("请填写职位描述")
            flag = false
        }
        if (this.state.workEXP.start_time != 0) {
            params.start_time = this.state.workEXP.start_time
        } else {
            message.info("请填写开始工作时间")
            flag = false
        }
        if (this.state.workEXP.end_time != 0) {
            params.end_time = this.state.workEXP.end_time
        } else {
            message.info("请填写结束工作时间")
            flag = false
        }

        if (flag) {
            if (this.state.workEXP.id == "") {
                http.addWorkExperienceList(params).then((res) => {
                    if (res.result == 1) {
                        message.success("修改成功")
                        this.setState(state => {
                            state.imformationModalVisible3 = false
                            state.confirmLoading = false
                            state.workEXP.id = ""
                            state.workEXP.name = ""
                            state.workEXP.position = ""
                            state.workEXP.description = ""
                            state.workEXP.start_time = 0
                            state.workEXP.end_time = 0
                        })
                        this.getWorkExperience()
                        this.formRef3.current.resetFields()
                    } else {
                        message.warn("修改失败")
                        this.setState(state => {
                            state.imformationModalVisible3 = false
                            state.confirmLoading = false
                            state.workEXP.id = ""
                            state.workEXP.name = ""
                            state.workEXP.position = ""
                            state.workEXP.description = ""
                            state.workEXP.start_time = 0
                            state.workEXP.end_time = 0
                        })
                        this.formRef3.current.resetFields()
                    }
                })
            } else {
                var id = this.state.workEXP.id
                http.updataWorkExperiencenList(id, params).then((res) => {
                    if (res.result == 1) {
                        message.success("修改成功")
                        this.setState(state => {
                            state.imformationModalVisible3 = false
                            state.confirmLoading = false
                            state.workEXP.id = ""
                            state.workEXP.name = ""
                            state.workEXP.position = ""
                            state.workEXP.description = ""
                            state.workEXP.start_time = 0
                            state.workEXP.end_time = 0
                        })
                        this.getWorkExperience()
                        this.formRef3.current.resetFields()
                    } else {
                        message.warn("修改失败")
                        this.setState(state => {
                            state.imformationModalVisible3 = false
                            state.confirmLoading = false
                            state.workEXP.id = ""
                            state.workEXP.name = ""
                            state.workEXP.position = ""
                            state.workEXP.description = ""
                            state.workEXP.start_time = 0
                            state.workEXP.end_time = 0
                        })
                        this.formRef3.current.resetFields()
                    }
                })
            }
        } else {
            this.setState({
                confirmLoading: false
            })
        }
    }

    editWorkEXP(e) {
        // console.log(e)
        this.setState({
            workEXP: e,
            imformationModalVisible3:true
        })
    }

    deleteWorkEXP(e) {
        http.deleteWorkExperiencenList(e.id).then((res) => {
            if (res.result == 1) {
                message.success("删除成功")
                this.getWorkExperience()
            } else {
                message.warn("删除失败")
            }
        })
    }

    showInformationModal3 = () => {

        this.setState({
            imformationModalVisible3: true,
            workEXP: {
                id: "",
                name: "",
                position: "",
                description: "",
                start_time: 0,
                end_time: 0
            },
        })
    }

    getWorkExperience() {
        http.getWorkExperienceList().then((res) => {
            this.setState({
                workList: res.data
            })
        })
    }

    imformationModalcancle3 = () => {
        this.setState({
            imformationModalVisible3: false,
            confirmLoading: false
        })
        this.setState(state => {
            state.workEXP.id = ""
            state.workEXP.name = ""
            state.workEXP.position = ""
            state.workEXP.description = ""
            state.workEXP.start_time = 0
            state.workEXP.end_time = 0
        })
        // this.formRef2.current.resetFields()s
        this.getWorkExperience()
    }

    onChange3(date1) {
        this.setState(state => {
            state.workEXP.start_time = moment(date1[0]).valueOf()
            state.workEXP.end_time = moment(date1[1]).valueOf()
        })
    }
    render() {
        return (
            <div>
                <Row className='title-head' justify='space-between'>
                    <Col>
                        <Row>
                            <div className='title-logo'>

                            </div>
                            <div className='title-character'>
                                工作经历
                            </div>
                        </Row>
                    </Col>
                    <Col>
                        <div id='ignore4' className='edit' onClick={this.showInformationModal3}>
                            <img className='icon' src={require('./../../assets/add-icon.png')} alt="" />
                            添加
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className='item-box'>
                        {
                            this.state.workList == null ?
                                (
                                    <div></div>
                                )
                                :
                                (
                                    this.state.workList.map((e) => {
                                        return (
                                            <div className='edit-box' key={e.id}>
                                                <div className='item'>
                                                    <div className='edit-box'>
                                                        <div className='edit' onClick={() => this.editWorkEXP(e)}>
                                                            <img className='icon' src={require('./../../assets/edit-icon.png')} alt="" />
                                                            编辑
                                                        </div>
                                                        <div className='del' onClick={() => this.deleteWorkEXP(e)}>
                                                            <img className='icon' src={require('./../../assets/del-icon.png')} alt="" />
                                                            删除
                                                        </div>
                                                    </div>
                                                    <Row>
                                                        <div className='character33500'>
                                                            {e.name}
                                                        </div>
                                                        <div className='character82'>
                                                            {common.dateFormat(e.start_time)}
                                                            -
                                                            {common.dateFormat(e.end_time)}
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className='divide-box'>
                                                            <div className='character33500'>
                                                                {e.position}
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className='character33'>
                                                            {e.description}
                                                        </div>
                                                    </Row>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                        }
                    </div>
                </Row>
                <Modal title="工作经历" visible={this.state.imformationModalVisible3}
                    confirmLoading={this.state.confirmLoading}
                    onOk={this.imformationModalOK3.bind(this)} onCancel={this.imformationModalcancle3}
                    destroyOnClose
                    >
                    <Form name='imformation' ref={this.formRef3}>
                        <FormItem label="企业名称" >
                            <Input placeholder='企业名称' defaultValue={this.state.workEXP.name==""?null:this.state.workEXP.name} onChange={e => { this.setState(state => { state.workEXP.name = e.target.value }) }} />
                        </FormItem>
                        <FormItem label="任职时间" >
                            <RangePicker defaultValue={this.state.workEXP.start_time==0?null:[moment(this.state.workEXP.start_time),moment(this.state.workEXP.end_time)]} onChange={this.onChange3.bind(this)} />
                        </FormItem>
                        <FormItem label="担任职位" >
                            <Input placeholder='职位名称' defaultValue={this.state.workEXP.position==""?null:this.state.workEXP.position} onChange={e => { var value = e.target.value; this.setState(state => { state.workEXP.position = value }) }} />
                        </FormItem>
                        <FormItem label="职位描述" >
                            <TextArea rows={6} style={{ resize: 'none' }} placeholder='职位描述' defaultValue={this.state.workEXP.description==""?null:this.state.workEXP.description} onChange={e => { var value = e.target.value; this.setState(state => { state.workEXP.description = value }) }} />
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        )
    }
}
