import React, { Component } from 'react'
import { Col, Row,message } from 'antd'
import './companyCard.less'
import { Link } from 'react-router-dom'
import http from '../../http'
import common from '../../public/base'

export default class companyCard extends Component {
    constructor(props){
        super(props)
    }
    goDetail(e){
        if(!localStorage.getItem('token')){
            message.warn("请先登录")
        }else{
            const w = window.open('about:blank');
            w.location.href=common.JobURL+e.id;
        }
    }
    goDetailCompany(){
        if(!localStorage.getItem('token')){
            message.warn("请先登录")
        }else{
            const w = window.open('about:blank');
            var a=common.CompanyURL+this.props.company.id
            // const w = window.open(a);
            // console.log(a)
            w.location.href=a;
        }
    }
  render() {
    var a='/companyDetail?company='+this.props.company.id
    var company=this.props.company
    return (
      <div className='company-message-box'>
           <Row>
              <div className='company-box' onClick={()=>{this.goDetailCompany()}}>
                  <div className='company-logo'>
	                  <img src={company.logo} alt={''}/>
                  </div>
                  <div className='company-detail'>
                      <div className='job'>
                        {company.name}
                      </div>
                      <div className='box'></div>
                      <div className='detail'>
                        {company.scale.name}&nbsp;&nbsp;|&nbsp;&nbsp;{company.address.city}
                      </div>
                  </div>
              </div>
          </Row>
        <div className='position-box'>
            {
                company.position.length===0?
                (
                    <div className=''>
                        暂无职位
                    </div>
                )
                :
                (
                    <div className=''>
                        {
                            company.position.map((e,index)=>{
                                if(index<3){
                                return(
                                    <div style={{cursor:'pointer'}} onClick={()=>{this.goDetail(e)}} key={index}>
                                    {index===0?(<div></div>):(<div className='divider'></div>)}
                                        <Row justify='space-between' className='job-detail'>
                                            <Col className='job'>
                                                    {e.name}
                                            </Col>
                                            <Col className='salary'>
                                                    {e.salary.name.replace('"','')}
                                            </Col>
                                        </Row>
                                    <Row>
                                      <div className='detail'>
                                          {company.address.city}&nbsp;&nbsp;|&nbsp;&nbsp;{e.work_years.name.replace('"','')}&nbsp;&nbsp;|&nbsp;&nbsp;{e.education.name.replace('"','')}
                                      </div >
                                    </Row>
                                    </div>
                                )
                                }
                            })
                        }
                    </div>
                )
            //     company.position.map((e,index)=>{
            //         if(company.position.length==0){
            //             return (
            //             <div className=''>
            //                 暂无职位
            //             </div>
            //             )
            //         }
            //     })
            }
        </div>
              {/* <div >
            <div className='divider'></div>
                <Row justify='space-between' className='job-detail'>
                    <Col className='job'>
                            职位AAAAAAAaaaaaaaaaaAA
                    </Col>
                    <Col className='salary'>
                            18-30k·16薪
                    </Col>
                </Row>
            <Row>
              <div className='detail'>
                  杭州&nbsp;&nbsp;|&nbsp;&nbsp;经验不限&nbsp;&nbsp;|&nbsp;&nbsp;本科
              </div >
            </Row>
            </div>
            <div>
                <div className='divider'></div>
                <Row justify='space-between' className='job-detail'>
                    <Col className='job'>
                            职位AAAAAAAaaaaaaaaaaAA
                    </Col>
                    <Col className='salary'>
                            18-30k·16薪
                    </Col>
                </Row>
                <Row>
                    <div className='detail'>
                        杭州&nbsp;&nbsp;|&nbsp;&nbsp;经验不限&nbsp;&nbsp;|&nbsp;&nbsp;本科
                    </div >
                </Row>
            </div>
            <div>
                <div className='divider'></div>
                <Row justify='space-between' className='job-detail'>
                    <Col className='job'>
                            职位AAAAAAAaaaaaaaaaaAA
                    </Col>
                    <Col className='salary'>
                            18-30k·16薪
                    </Col>
                </Row>
                <Row>
                    <div className='detail'>
                        杭州&nbsp;&nbsp;|&nbsp;&nbsp;经验不限&nbsp;&nbsp;|&nbsp;&nbsp;本科
                    </div >
                </Row>
            </div> */}
                <button className='more' onClick={()=>{this.goDetailCompany()}}>
                        查看更多
                </button>
      </div>
    )
  }
}
