import React, {Component} from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import './jobDetail.less'
import {Col, message, Row, Tooltip} from 'antd'
import qs from 'qs';
import http from '../../http'

export default class jobDetail extends Component {
	constructor(props) {
		super(props)
		const {job} = qs.parse(props.location.search.replace(/^\?/, ''))
		this.state = ({
			jobID: job,
			jobDetail: {
				salary: {
					name: "薪资"
				},
				work_years: {
					name: "工作经验"
				},
				education: {
					name: "学历要求"
				},
				nature: 0,
				address: {
					province: "",
					city: "",
					region: "",
					address: ""
				},
				welfare: [],
				description: "",
				require: "",
				company: {
					logo: "",
					name: "",
					scale: {
						name: ""
					},
					address: {
						address: ""
					},
					industry: []
				},
				is_star: 0,
				is_apply: 0,
			}
		})
		this.jobLook()
	}

	componentDidMount() {
		this.getJobDetail()
		// this.jobLook()
	}

	getJobDetail() {
		http.getJobDetail(this.state.jobID).then((res) => {
			this.setState({
				jobDetail: res.data
			})
		})
	}

	jobLook() {
		http.jobLook(this.state.jobID).then((res) => {
		})
	}

	jobCollect() {
		var params = {
			star: this.state.jobDetail.is_star == 1 ? 0 : 1
		}
		http.jobStar(this.state.jobID, params).then((res) => {
			if (res.result == 1) {
				message.success("操作成功")
				this.getJobDetail()
			}
		})
	}

	jobPost() {
		if (this.state.jobDetail.is_apply == 1) {
			message.warn("已投递，请勿重复投递")
		} else {
			http.jobApply(this.state.jobID).then((res) => {
				if (res.result == 1) {
					message.success("投递成功")
					this.getJobDetail()
				}
			})
		}
	}

	render() {
		const jobDetail = this.state.jobDetail
		const description = this.state.jobDetail.description
		const industry = jobDetail.company.industry.map((item, index) => <span key={index}>{item.name}</span>)

		return (
			<div className='jd'>
				<Header></Header>
				<div className='jd-center'>
					<Row>
						<div className='jd-header'>
							<Row justify='space-between'>
								<Col>
									<div className='job-status'>
										招聘中
									</div>
								</Col>
								<Col>
									<div className='button-box'>
										<div className='collect-job'>
											<div className='button-detail' onClick={() => this.jobCollect()}>
												<img className='button-icon' src={require('./../../assets/collect-job-icon.png')} alt=""/>
												{this.state.jobDetail.is_star == 0 ? "收藏简历" : "已收藏"}
											</div>
										</div>
										<div className='post-job' onClick={() => this.jobPost()}>
											<div className='button-detail'>
												<img className='button-icon' src={require('./../../assets/post-job-icon.png')} alt=""/>
												{this.state.jobDetail.is_apply == 0 ? "投递简历" : "已投递"}
											</div>
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								<div className='first-row'>
									<div className='job-name'>
										{jobDetail.name}
									</div>
									<div className='salary'>
										{jobDetail.salary.name.replace('"', '')}
									</div>
								</div>
							</Row>
							<Row>
								<div className='second-row'>
									{jobDetail.work_years.name.replace('"', '')}
									&nbsp;&nbsp;|&nbsp;&nbsp;
									{jobDetail.education.name.replace('"', '')}
									&nbsp;&nbsp;|&nbsp;&nbsp;
									{jobDetail.address.city.replace(/"/g, '')}
									&nbsp;&nbsp;|&nbsp;&nbsp;
									{jobDetail.nature == 0 ? '全职' : jobDetail.nature == 1 ? '兼职' : '实习'}
								</div>
							</Row>
							<Row wrap={true}>
								<div className='third-row'>
									{
										jobDetail.welfare.map((e) => {
											return (
												<div className='welfare' key={e.id}>{e.name.replace('"', '')}</div>
											)
										})
									}
								</div>
							</Row>
						</div>
					</Row>
					<Row>
						<Col className='jd-first-col'>
							<Row className='title-head'>
								<div className='title-logo'>

								</div>
								<div className='title-character'>
									职位描述
								</div>
							</Row>
							<div className='title-detail'>
								{description}
							</div>
							<div className='jd-divide'>

							</div>
							<Row className='title-head'>
								<div className='title-logo'>

								</div>
								<div className='title-character'>
									任职要求
								</div>
							</Row>
							<div className='title-detail'>
								{jobDetail.require}
							</div>
							<div className='jd-divide'>

							</div>
							<Row className='title-head'>
								<div className='title-logo'>

								</div>
								<div className='title-character'>
									工作地点
								</div>
							</Row>
							<div className='title-detail'>
								{/* {jobDetail.address.province.replace(/"/g, '')}
                        {jobDetail.address.city.replace(/"/g, '')}
                        {jobDetail.address.region.replace(/"/g, '')} */}
								{jobDetail.address.address.replace(/"/g, '')}
							</div>
						</Col>
						<Col className='jd-second-col'>
							<div className='basic-message'>
								公司基本信息
							</div>
							<Row>
								<div className='first-row'>
									<Col>
										<img className='logo' src={jobDetail.company.logo}/>
									</Col>
									<Col>
										<Tooltip placement="top" title={jobDetail.company.name}>
											<div className='name'> {jobDetail.company.name}</div>
										</Tooltip>
									</Col>
								</div>
							</Row>
							<Row>
								<div className="item">
									<img className='icon' src={require('./../../assets/number-icon.png')} alt=""/>
									{jobDetail.company.scale.name}
								</div>
							</Row>
							{/*<Row>*/}
							{/*	<div className="item">*/}
							{/*		<img className='icon' src={require('./../../assets/locate-icon.png')} alt=""/>*/}
							{/*		{jobDetail.company.address.address}*/}
							{/*	</div>*/}
							{/*</Row>*/}
							<Row>
								<div className="item">
									<Row wrap={false}>
										<Col flex="none">
											<img className='icon' src={require('./../../assets/locate-icon.png')} alt=""/>
										</Col>
										<Col flex="auto">{jobDetail.company.address.address}</Col>
									</Row>
								</div>
							</Row>
							<Row>
								<div className="item">
									<img className='icon' src={require('./../../assets/kind-icon.png')} alt=""/>
									{industry}
								</div>
							</Row>
						</Col>
					</Row>
				</div>
				<Footer></Footer>
			</div>
		)
	}
}
