import {Col, Row} from 'antd'
import React, {Component} from 'react'
import './recruitmentCard.less'
import common from '../../public/base'

export default class recruitmentCard extends Component {
	constructor(props) {
		super(props)

	}

	goDetail() {
		const w = window.open('about:blank');
		w.location.href = common.JobURL + this.props.job.id;
	}

	render() {
		const job = this.props.job
		return (
			<div className='recruitment-card' onClick={this.goDetail.bind(this)}>
				<Row>
					<Col span={10}>
						<Row>
							<div className='job'>
								{/* {job.name} */}
								{this.props.job.name}
							</div>
						</Row>
						<Row>
							<div className='salary'>
								{this.props.job.salary.name.replace('"', '')}
							</div>
							<div className='detail'>
								{this.props.job.address.city.replace(/"/g, '')}
								&nbsp;&nbsp;|&nbsp;&nbsp;
								{this.props.job.work_years.name.replace('"', '')}
								&nbsp;&nbsp;|&nbsp;&nbsp;
								{this.props.job.education.name.replace('"', '')}
							</div>
						</Row>
					</Col>
					<Col span={14}>
						<div className='rc-welfare'>
							{
								this.props.job.welfare.map((e, index) => {
									return (
										<div className='welfare'>
											{e.name.replace('"', '')}
										</div>
									)
								})
							}
						</div>
					</Col>
				</Row>
			</div>
		)
	}
}
