import React, {Component} from 'react'
import {Input, Select, Button, message, Cascader} from 'antd';
import {NavLink as Link, router, useHistory} from 'react-router-dom'
import './searchInput.less'
import http from '../../http'

const {Search} = Input;
const {Option} = Select;

// const options = [
// 	'杭州市',
// 	'苏州市'
// ];

export default class searchInput extends Component {
	constructor(props) {
		super(props)
		// this.state={
		//   options:[]
		// }
		// this.getCity()
		if (props.keyWords == undefined) {
			this.state = {
				keyWords: '',
				options: [],
				defaultCity: [],
				provinceInfo: [],
				cityInfo: [],
				city: 0
			}
		} else {
			this.state = {
				options: [],
				provinceInfo: [],
				defaultCity: [],
				cityInfo: [],
				city: 0,
				keyWords: props.keyWords
			}
		}
		this.getAllCityData()
	}

	// getCity(){
	// }
	getAllCityData() {
		http.getAddressInfo().then(res => {
			let options = [{
				value: 0,
				label: '不限',
				children: [{
					value: 0,
					label: '不限地区',
				}]
			}];
			res.data.map(province => {
				let children = [];
				province.sub.map(city => {
					children.push({
						value: city.id,
						label: city.name,
					})
				})
				options.push({
					value: province.id,
					label: province.name,
					children,
				})
			});
			this.setState({
				options
			})
		})
	}

	// getProvinceInfo() {
	// 	http.getMapInfo({id: 1}).then(res => {
	// 		console.log(res);
	// 		this.setState({
	// 			provinceInfo: res.data.sub
	// 		})
	// 	})
	// }

	// handleProvinceChange(value) {
	// 	http.getMapInfo({id: value}).then(res => {
	// 		this.setState({
	// 			cityInfo: res.data.sub
	// 		})
	// 		// setCityInfo(res.data.sub);
	// 	})
	//
	// }
	//
	// handleCityChange(value) {
	// 	this.setState(state => {
	// 		state.city = value
	// 	})
	// }
	selectedCity(value) {
		if (value === undefined) {
			this.setState(state => {
				state.defaultCity = [];
				state.city = 0;
			})
		} else {
			this.setState(state => {
				state.city = value[value.length - 1];
				state.defaultCity = value;
			})
		}
	}

	Search() {
		if (!localStorage.getItem('token')) {
			message.warn("请先登录")
		} else {
			var params = {
				_b: 1,
				_e: 10,
				// salary:44,
				// education:40,
				// work_years:65,
				key_words: this.state.keyWords
			}
			var params2 = {
				key_words: this.state.keyWords
			}

			localStorage.setItem("keyWords", this.state.keyWords)
			localStorage.setItem("city", this.state.city)
			localStorage.setItem("defaultCity", this.state.defaultCity)

			if (this.state.city !== 0) {
				params.city = this.state.city
				params2.city = this.state.city
			}

			// http
			http.getSearchPosition(params).then(res1 => {
				if (res1.result == 1) {
					//message.success("成功")
					http.getSearchCompany(params2).then(res2 => {
						localStorage.setItem("companyList", JSON.stringify(res2.data))
						this.props.history.push({
							pathname: '/searchPage',
							state: {keyWords: this.state.keyWords, defaultCity: this.state.defaultCity, city: this.state.city}
						})
					})
				} else {
					message.error("系统故障，请重试")
				}
			})
		}
	}

	render() {
		return (
			<div className='search-box'>
				<Input.Group size='large' compact>
					<div className={'search-left'} style={{borderRadius: '5px 0 0 5px'}}>
						<Cascader className={'search-city'} options={this.state.options} onChange={(e) => this.selectedCity(e)}
						          placeholder="选择省市"
						          bordered={false} allowClear={false}/>
						<Input className={'search-input'} placeholder={this.state.keyWords == '' ? "搜索职位/公司" : ""}
						       value={this.state.keyWords} bordered={false}
						       onChange={e => {
							       this.setState({keyWords: e.target.value})
						       }}/>
					</div>
					{/* <Link to={{pathname:'/searchPage',}}> */}
					<Button className={'search-button'} style={{borderRadius: '0 5px 5px 0'}} type="primary"
					        onClick={this.Search.bind(this)}>搜索</Button>
					{/* </Link> */}
				</Input.Group>
			</div>
		)
	}
}
