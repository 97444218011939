import React, { Component } from 'react'
import { Col, Modal, Row, Upload, Input, Button, Avatar, message, Form, Select, DatePicker, InputNumber } from 'antd'
import http from '../../http'
import FormItem from 'antd/lib/form/FormItem'
import common from '../../public/base'

const { Option } = Select;

const validateMessages = {
    required: '${label} 是必填项!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    }
}
export default class hopePosition extends Component {
    formRef2 = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            confirmLoading: false,
            imformationModalVisible2: false,
            hopeList: [],
            id: "",
            name: "",
            province_id: 0,
            province: "",
            city_id: 0,
            city: "",
            min_pay: 0,
            max_pay: 0,
            provinceInfo: [],
            cityInfo: []
        }
        this.getHopePosition()
        this.getProvinceInfo()
    }
    
    handleProvinceChange(value) {
        http.getMapInfo({ id: value }).then(res => {
            this.setState({
                cityInfo: res.data.sub
            })
            // setCityInfo(res.data.sub);
        })

    }
    handleCityChange(value) {
        this.setState(state => {
            state.city_id = value
        })
    }
    editHopePosition(e){
        // console.log(e)
        // this.showInformationModal2()
        // this.formRef2.current.resetFields()
        this.setState({
          id:e.id,
          name:e.name,
          min_pay:e.min_pay,
          max_pay:e.max_pay,
          province_id: e.province_id,
          province: e.province,
          city_id: e.city_id,
          city: e.city,
          imformationModalVisible2: true
        })
        // this.formRef2.current.resetFields()
    }

    deleteHopePosition(e){
        http.deletePositionHopeList(e.id).then((res)=>{
          if(res.result==1){
            message.success("删除成功")
           this.getHopePosition()
          }else{
            message.warn("删除失败")
          }
        })
      }

    // deleteConfirm(e){
    //     Modal.confirm({
    //         width:700,
    //         title:'确定删除此项？'
    //         onOk:
    //     })
    // }

    getProvinceInfo() {
        http.getMapInfo({ id: 1 }).then(res => {
            this.setState({
                provinceInfo: res.data.sub
            })
        })
    }

    imformationModalcancle2() {
        this.setState({
            imformationModalVisible2: false,
            confirmLoading: false,
            id: "",
            name: "",
            city: "",
            min_pay: 0,
            max_pay: 0
        })
        this.formRef2.current.resetFields()
        this.getHopePosition()
        // this.formRef2.current.resetFields()
    }
    showInformationModal2 = () => {
      
        this.setState({
            imformationModalVisible2: true,
            province: "",
            city: "",
        })
        // this.formRef2.current.setFieldsValue({
        //     position:this.state.name
        // })
        // this.formRef2.current.resetFields()
    }
    getHopePosition() {
        http.getPositionHopeList().then((res) => {
            this.setState({
                hopeList: res.data
            })
        })
    }

    imformationModalOK2() {
        this.setState({
            confirmLoading: true
        })
        var flag = true
        var params = {}
        if (this.state.name != "") {
            params.name = this.state.name
        } else {
            message.info("请填写期望职位")
            flag = false
        }
        if (this.state.max_pay != "") {
            params.max_pay = this.state.max_pay
        } else {
            message.info("请填写期望薪资")
            flag = false
        }
        if (this.state.min_pay != "") {
            params.min_pay = this.state.min_pay
        } else {
            message.info("请填写期望薪资")
            flag = false
        }
        if (this.state.city_id != "") {
            params.city = this.state.city_id
        } else {
            message.info("请填写期望城市")
            flag = false
        }
        if (flag) {
            if (this.state.id == "") {
                http.addPositionHopeList(params).then((res) => {
                    if (res.result == 1) {
                        message.success("修改成功")
                        this.setState({
                            imformationModalVisible2: false,
                            confirmLoading: false,
                            id: "",
                            name: "",
                            city: "",
                            min_pay: 0,
                            max_pay: 0
                        })
                        this.getHopePosition()
                        this.formRef2.current.resetFields()
                    } else {
                        message.warn("修改失败")
                        this.setState({
                            imformationModalVisible2: false,
                            confirmLoading: false,
                            id: "",
                            name: "",
                            city: "",
                            min_pay: 0,
                            max_pay: 0
                        })
                        this.formRef2.current.resetFields()
                    }
                })
            } else {
                var id = this.state.id
                if (this.state.city_id != 0) {
                    params.city = this.state.city_id
                } else {
                    params.city = 2
                }
                http.updataPositionHopeList(id, params).then((res) => {
                    if (res.result == 1) {
                        message.success("修改成功")
                        this.setState({
                            imformationModalVisible2: false,
                            confirmLoading: false,
                            id: "",
                            name: "",
                            city: "",
                            min_pay: 0,
                            max_pay: 0
                        })
                        this.getHopePosition()
                        this.formRef2.current.resetFields()
                    } else {
                        message.warn("修改失败")
                        this.setState({
                            imformationModalVisible2: false,
                            confirmLoading: false,
                            id: "",
                            name: "",
                            city: "",
                            min_pay: 0,
                            max_pay: 0
                        })
                        this.formRef2.current.resetFields()
                    }
                })
            }
        } else {
            this.setState({
                confirmLoading: false
            })
        }
    }
    render() {
        return (
            <div>
                <Row className='title-head' justify='space-between'>
                    <Col>
                        <Row>
                            <div className='title-logo'>

                            </div>
                            <div className='title-character'>
                                期望职位
                            </div>
                        </Row>
                    </Col>
                    <Col>
                        <div id='ignore3' className='edit' onClick={()=>{this.showInformationModal2()}}>
                            <img className='icon' src={require('./../../assets/add-icon.png')} alt="" />
                            添加
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className='item-box'>
                        <div className='edit-box'>
                            {
                                this.state.hopeList == null ?
                                    (
                                        <div></div>
                                    )
                                    :
                                    (
                                        this.state.hopeList.map((e) => {
                                            return (
                                                <div className='item' key={e.id}>
                                                    <div className='edit-box'>
                                                        <div className='edit' onClick={() => { this.editHopePosition(e) }}>
                                                            <img className='icon' src={require('./../../assets/edit-icon.png')} alt="" />
                                                            编辑
                                                        </div>
                                                        <div className='del' onClick={() => this.deleteHopePosition(e)}>
                                                            <img className='icon' src={require('./../../assets/del-icon.png')} alt="" />
                                                            删除
                                                        </div>
                                                    </div>
                                                    <div className='character33'>
                                                        <img className='icon-2' src={require('./../../assets/job-icon.png')} alt="" />
                                                        {e.name}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                        <img className='icon-2' src={require('./../../assets/salary-icon.png')} alt="" />
                                                        ￥{e.min_pay}-￥{e.max_pay}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                        <img className='icon-2' src={require('./../../assets/locate-icon.png')} alt="" />
                                                        {e.city}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                            }
                        </div>
                    </div>
                </Row>
                <Modal title="期望职位" visible={this.state.imformationModalVisible2}
                    confirmLoading={this.state.confirmLoading}
                    onOk={this.imformationModalOK2.bind(this)} onCancel={this.imformationModalcancle2.bind(this)}
                    destroyOnClose
                    >
                    <Form name='imformation' ref={this.formRef2} validateMessages={validateMessages}
                          onFinish={this.imformationModalOK2.bind(this)}
                          onFinishFailed={()=>{message.error("请完善信息")}}>
                        <FormItem label="期望职位"  rules={[{ required: true }]}>
                            <Input placeholder={'期望职位'} value={this.state.name} onChange={e => { var value = e.target.value; this.setState({ name : value }) }} />
                        </FormItem>
                        <FormItem label="最低薪资"  rules={[{ required: true }]}>
                            <InputNumber placeholder={'最低薪资'} value={this.state.min_pay==0?null:this.state.min_pay} onChange={e => { var value = e; this.setState( { min_pay : value }) }} />
                        </FormItem>
                        <FormItem label="最高薪资"  rules={[{ required: true }]}>
                            <InputNumber placeholder={'最高薪资'} value={this.state.max_pay==0?null:this.state.max_pay} onChange={e => { var value = e; this.setState( { max_pay : value }) }} />
                        </FormItem>
                        <Form.Item label="期望城市"  rules={[{ required: true }]}>
                            <Select placeholder='请选择省' defaultValue={this.state.province==""?null:this.state.province} onChange={(e) => this.handleProvinceChange(e)} style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                {this.state.provinceInfo.map(province => (
                                    <Option key={province.id} value={province.id}>{province.name}</Option>
                                ))}
                            </Select>
                            <span style={{ display: 'inline-block', width: '24px' }}></span>
                            <Select placeholder='请选择市' defaultValue={this.state.city==""?null:this.state.city} onChange={(e) => this.handleCityChange(e)} style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                                {this.state.cityInfo.map(e => (
                                    <Option key={e.id} value={e.id}>{e.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}
