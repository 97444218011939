import http from './api'

var apiMap = {

  /**接口 */
  
  baseUrl: 'https://test.zhinanche.com/api/v2/zhinanche-job-business-v2',
  // /**
  // 获取活动列表
  // *
  // @method activityList get
  // *
  // [@param] {object} params 
  // *
  // @return {object} 请求处理回调[then(function)]
  // */
  // recentClass: () => http.get(`/zhinanche-school/recentClass`),
  // /**
  /*获取职位福利列表
  *
  @method activityList get
  *
  [@param] {object} params 
  *
  @return {object} 请求处理回调[then(function)]
  */
  jobWelfare: () => http.get(`common/dict/welfare`),
  /**
   * 修改tag
   * @returns 
   */
  updataTag: (param) => http.put(`/user/tag`,param,{token:true}),
  /*获取职位福利列表
  *
  @method activityList get
  *
  [@param] {object} params 
  *
  @return {object} 请求处理回调[then(function)]
  */
  tagProfession: () => http.get(`/common/dict/position/tag/profession`),
  tagBrand: () => http.get(`/common/dict/position/tag/brand`),
  tagSoftware: () => http.get(`/common/dict/position/tag/software`),
  tagApply: () => http.get(`/common/dict/position/tag/apply`),
  tagSkill: () => http.get(`/common/dict/position/tag/skill`),
    /**
   获取地图信息
      *
      @method activityList get    
      *
      [@param] {object} 
      */
      getMapInfo: (params) => http.get(`/common/dict/map`, { params: params }),
	    getAddressInfo: () => http.get(`/common/dict/map/all/city`),
  /**
  发送验证码
  *
  @method activityList post
  *
  [@param] {object} params 
  *
  @return {object} 请求处理回调[then(function)]
  */
  getCode: (params) => http.post(`/common/sms/send`, params),
  /**
  验证码登录
  *
  @method activityList post
  *
  [@param] {object} params {username,code}
  */
  yzmLogin: (params) => http.post(`/user/login/sms/v2`, params),
  /**
    密码登录
    *
    @method activityList post
    *
    [@param] {object} params {username,password}
    */
  passwordLogin: (params) => http.post(`/user/login/v2`, params),
  /**
  注册账号
  *
  @method activityList post
  *
  [@param] {object} params {username,password}
  */
  register: (data) => http.post(`/user/register`, data.params, { headersData: data.headersData }),
  /**
  找回密码
  *
  @method activityList put    
  *
  [@param] {object} params {username,password}
  */
  getPassword: (data) => http.put(`/user/password/re`, data.params, { headersData: data.headersData }),
  /**
    获取用户信息
    *
    @method activityList get    
    *
    [@param] {object} params {username,password}
    */
  getUserInfo: () => http.get(`/user/info`, { token: true }),
  /**
   * 
   获取名企信息
   @method hotCompany get
   * @returns 
   */
  hotCompany: (params) => http.get('/company/hot', {params:params,token:true}),
  /**
   * 
   获取职位信息(首页)
   @method getHotPosition get
   * 
   */
  getHotPosition: (params) => http.get('/position/hot',{params:params,token:true}),
  /**
   * 
   搜索职位信息
   @method getSearchPosition get
   * 
   */
  getSearchPosition: (params) =>http.get('/position/search',{params:params,token:true}),
  /**
   * 
   搜索公司信息
   @method getSearchCompany get
   * 
   */
   getSearchCompany: (params) =>http.get('/company/search',{params:params,token:true}),
  /**
   * 
   搜索职位信息
   @method getSearchPositionTotal get
   * 
   */
  getSearchPositionTotal: (params) =>http.get('/position/search/total',{params:params,token:true}),
  /**  工作经验
  *
  @method activityList get    
  *
  [@param] {object}  
  */
  getWorkYear: () => http.get(`/common/dict/work_years`),
  /**
   学历
  *
  @method activityList get    
  *
  [@param] {object}  
  */
  getEducation: () => http.get(`/common/dict/education`),
  /**
   薪资
  *
  @method activityList get    
  *
  [@param] {object}  
  */
  getSalary: () => http.get(`/common/dict/salary`),
  /**   获取职位详情
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getJobDetail: (eid) => http.get(`/position/${eid}`, { token: true }),
    /**   职位浏览
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  jobLook: (eid) => http.put(`/position/${eid}/look`, { token: true }),
    /**   收藏职位
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  jobStar: (eid,params) => http.put(`/position/${eid}/star`,params, {token: true }),
    /**   投递职位
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  jobApply: (eid) => http.put(`/position/${eid}/apply`, {token: true }),
  /**   获取公司详情
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getCompanyDetail: (eid) => http.get(`/company/${eid}`, { token: true }),
    /**   浏览记录
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getVisitedJob: (params) => http.get(`/position/user/look`, {params:params, token: true }),
  /**   收藏职位数量
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getJobCollection: () => http.get(`/position/user/star/total`, {token: true }),
    /**   收藏职位列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getJobCollectionList: (params) => http.get(`/position/user/star`, {params:params,token: true }),
  /**   投递职位数量
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getJobApply: () => http.get(`/position/user/apply/total`, {token: true }),
    /**   投递职位列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getJobApplyList: (params) => http.get(`/position/user/apply`, {params:params,token: true }),
  /**   获取求职意向列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getPositionHopeList: () => http.get(`/user/hope/position`),
  /**   新增求职意向列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  addPositionHopeList: (params) => http.post(`/user/hope/position`,params,{token:true}),
  /**   修改求职意向列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  updataPositionHopeList: (id,params) => http.put(`/user/hope/position/${id}`,params,{token:true}),
  /**   删除求职意向列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  deletePositionHopeList: (id) => http.delete(`/user/hope/position/${id}`,{token:true}),
  /**   获取工作经历列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getWorkExperienceList: () => http.get(`/user/experience/work`),
    /**   添加工作经历
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  addWorkExperienceList: (params) => http.post(`/user/experience/work`,params,{token:true}),
    /**   修改工作经历列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  updataWorkExperiencenList: (id,params) => http.put(`/user/experience/work/${id}`,params,{token:true}),
      /**   删除工作经历列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  deleteWorkExperiencenList: (id) => http.delete(`/user/experience/work/${id}`,{token:true}),
    /**   获取项目经历列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getProjectExperienceList: () => http.get(`/user/experience/project`),
      /**   添加工作经历
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  addProjectExperienceList: (params) => http.post(`/user/experience/project`,params,{token:true}),
    /**   修改工作经历列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  updataProjectExperiencenList: (id,params) => http.put(`/user/experience/project/${id}`,params,{token:true}),
      /**   删除工作经历列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  deleteProjectExperiencenList: (id) => http.delete(`/user/experience/project/${id}`,{token:true}),
  /**   获取教育经历列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getEducationExperienceList: () => http.get(`/user/experience/edu`),
  /**   添加教育经历列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  addEducationExperienceList: (params) => http.post(`/user/experience/edu`,params,{token:true}),
  /**   修改教育经历列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  updataEducationExperiencenList: (id,params) => http.put(`/user/experience/edu/${id}`,params,{token:true}),
  /**   删除教育经历列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  deleteEducationExperiencenList: (id) => http.delete(`/user/experience/edu/${id}`,{token:true}),
  /**   获取技能列表
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  getTagList: () => http.get(`/user/tag`),
    /**   修改用户信息
  *
  @method activityList put    
  *
  [@param] {object} 
  */
  putUserInfo: (params) => http.put(`/user/info`, params,{token: true }),



}

export default apiMap;