import {Col, Row} from 'antd'
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import './searchPageCompanyCard.less'
import common from '../../public/base'

export default class searchPageCompanyCard extends Component {
	constructor(props) {
		super(props)

	}

	goDetailCompany() {
		const w = window.open('about:blank');
		w.location.href = common.CompanyURL + this.props.company.id;
	}

	render() {
		let a = '/companyDetail?company=' + this.props.company.id
		const company = this.props.company
		return (
			<div className='searchPageCompanyCard' onClick={() => {
				this.goDetailCompany()
			}}>
				<Row justify='space-between'>
					<Col>
						<Row>
							<div className='spcc-company-logo'>
								<img src={company.logo} alt=""/>
							</div>
							<div className='company-detail'>
								<div className='job'>
									{company.name}
								</div>
								<div className='box'/>
								<div className='detail'>
									{company.scale.name}&nbsp;&nbsp;|&nbsp;&nbsp;{company.address.city}
								</div>
							</div>
						</Row>
					</Col>
					<Col>
						<Row>
							<div className='job-number'>
								<div className='number'>
									{company.position.length}
								</div>
								<div className='box'>

								</div>
								<div className='text'>
									在招职位
								</div>
							</div>
						</Row>
					</Col>
				</Row>
			</div>
		)
	}
}
