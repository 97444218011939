import {Col, Row,message} from 'antd'
import React, {Component} from 'react'
import './visitedJob.less'
import http from '../../http'
import common from '../../public/base'

export default class visitedJob extends Component {
	constructor(props) {
		super(props)
		this.getVisitedJob()
		this.state = ({
			visitedJob: []
		})
	}

	getVisitedJob() {
		var params = {
			_b: 1,
			_e: 10
		}
		http.getVisitedJob(params).then((res) => {
			this.setState({
				visitedJob: res.data
			})
		})
	}
    goDetail(e){
        if(!localStorage.getItem('token')){
            message.warn("请先登录")
        }else{
            const w = window.open('about:blank');
            w.location.href=common.JobURL+e.id;
        }
    }
	render() {
		return (
			<div className='visited'>
				<div className='v-header'>
					我看过的职位
				</div>
				<div className='v-divide-first'></div>
				<div className='v-job-card-box'>
					{
						this.state.visitedJob.map((e, index) => {
							return (
								<div style={{cursor:'pointer'}} key={e.id} className='v-job-card' onClick={()=>{this.goDetail(e)}}>
									<Row justify='space-between'>
										<Col>
											<div className='v-job-name'>
												{e.name}
											</div>
										</Col>
										<Col>
											<div className='v-salary'>
												{e.salary.name.replace('"', '')}
											</div>
										</Col>
									</Row>
									<Row>
										<div className='v-company'>
											{e.company.name}
										</div>
									</Row>
									{index + 1 < this.state.visitedJob.length ? <div className='v-divide'></div> : null}
								</div>
							)
						})
					}
					{/* <div className='v-job-card'>
            <Row justify='space-between'>
                <Col> 
                <div className='v-job-name'>
                    职位名称aaaaaaaaaaaaaaaaaaaaaaa
                </div>
                </Col>
                <Col>
                <div className='v-salary'>
                    04-07·13薪
                </div>
                </Col>
            </Row>
            <Row>
                <div className='v-company'>
                    公司名称啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊
                </div>
            </Row>
            <div className='v-divide'></div>
            </div>
            <div className='v-job-card'>
            <Row justify='space-between'>
                <Col> 
                <div className='v-job-name'>
                    职位名称aaaaaaaaaaaaaaaaaaaaaaa
                </div>
                </Col>
                <Col>
                <div className='v-salary'>
                    04-07·13薪
                </div>
                </Col>
            </Row>
            <Row>
                <div className='v-company'>
                    公司名称啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊
                </div>
            </Row>
            <div className='v-divide'></div>
            </div>
            <div className='v-job-card'>
            <div className='v-divide'></div>
            <Row justify='space-between'>
                <Col> 
                <div className='v-job-name'>
                    职位名称aaaaaaaaaaaaaaaaaaaaaaa
                </div>
                </Col>
                <Col>
                <div className='v-salary'>
                    04-07·13薪
                </div>
                </Col>
            </Row>
            <Row>
                <div className='v-company'>
                    公司名称啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊
                </div>
            </Row>

            </div> */}
				</div>
			</div>
		)
	}
}
