import React, {Component, useEffect, useRef, useState} from 'react'
import {Col, Row, Form, Input, Button, message, Menu,} from 'antd'
import {useHistory} from "react-router-dom";
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import './homePage.less'
import http from "../../http"

///验证码登录模块
const CodeHtml = (props) => {
	const [form] = Form.useForm();
	let history = useHistory();
	const [loading, setLoading] = useState(false)
	const [time, setTime] = useState(60)
	const timeRef = useRef()//设置延时器

	useEffect(() => {
		//如果设置倒计时且倒计时不为0
		if (time && time !== 0)
			timeRef.current = setTimeout(() => {
				setTime(time => time - 1)
			}, 1000)
		if (time === 0) {
			setLoading(false)
			clearInterval(timeRef.current)
		}
		//清除延时器
		return () => {
			clearInterval(timeRef.current)
		}
	}, [time])

	//获取验证码
	const getCode = function () {
		const values = form.getFieldsValue();
		if (values.username) {
			http.getCode({send: values.username}).then(res => {
				if (res.result === 1) {
					message.success('发送成功')
					setLoading(true)
					setTime(60)
					// form.setFieldsValue({
					//   code: res.data
					// })
				}
			})
		} else {
			message.info('请输入手机号');
		}
	}

	const codeFinish = value => {
		http.yzmLogin(value).then(res => {
			if (res.result === 1) {
				message.success('登录成功！');
				localStorage.setItem('token', res.data.token);
				http.getUserInfo().then(res2 => {
					localStorage.setItem('userInfo', JSON.stringify(res2.data));
					if (res2.result === 1) {
						if (res2.data.status === 2 || res2.data.status === 3) {
							history.push('/indexPage')
						} else {
							history.push('/')
						}
					}
				})
			}
		})
	}

	return (
		<Form form={form} name="normal_login" className="login-form" onFinish={codeFinish}>
			<Form.Item name="username" rules={[{required: true, len: 11, message: '请输入手机号'}]}>
				<Input placeholder="请输入手机号"/>
			</Form.Item>
			<Form.Item name="code" rules={[{required: true, len: 6, message: '请输入验证码'}]}>
				<Row className='code-input-row'>
					<Col span='16'><Input bordered={false} placeholder="请输入验证码"/></Col>
					{!loading ?
						<Col span='8'>
							<div onClick={getCode} className="get-code">获取验证码</div>
						</Col>
						:
						<Col span='8'>
							<div onClick={() => {
								message.error("验证码发送过于频繁，请等待")
							}} className="get-code">{time}秒后重新发送
							</div>
						</Col>
					}
				</Row>
			</Form.Item>
			<Form.Item className='login-btn'>
				<Button type="primary" htmlType="submit" className="code-login-form-button form-button">立即登录</Button>
				{/* <a href="">register now!</a> */}
			</Form.Item>
			<Form.Item>
				<Button onClick={() => props.register()} style={{width: '50%'}}
				        className="code-register-form-button form-button">立即注册账号</Button>
				<Button onClick={() => {
					window.location.href = 'https://business.job.zhinanche.com/#/login'
				}} style={{width: '50%'}} className="code-register-form-button form-button">企业端登录</Button>
			</Form.Item>
		</Form>
	)
}

///密码登录模块
const PasswordHtml = (props) => {
	const [passForm] = Form.useForm();
	let history = useHistory();

	const passwordLoagin = values => {
		http.passwordLogin(values).then(res => {
			if (res.result === 1) {
				message.success('登录成功！');
				localStorage.setItem('token', res.data.token);
				http.getUserInfo().then(res2 => {
					localStorage.setItem('userInfo', JSON.stringify(res2.data));
					if (res2.result === 1) {
						if (res2.data.status === 2 || res2.data.status === 3) {
							history.push('/indexPage')
						} else {
							history.push('/')
						}
					}
				})
			}
		})
	}

	return (
		<Form form={passForm} onFinish={passwordLoagin} name="normal_login" className="login-form">
			<Form.Item name="username" rules={[{required: true, len: 11, message: '请输入手机号'}]}>
				<Input placeholder="请输入手机号"/>
			</Form.Item>
			<Form.Item name="password" rules={[{required: true, message: '请输入密码'}]}>
				<div className="code-input-row">
					<Input.Password className='pass-input' bordered={false} placeholder="请输入密码"/>
				</div>
			</Form.Item>
			<Form.Item>
				{/* () => this.setState({ loginFlag: 'getPass' }) */}
				<Button type="link" onClick={() => props.forgetPassword()} block>
					忘记密码
				</Button>
			</Form.Item>
			<Form.Item className='login-btn'>
				<Button type="primary" htmlType="submit" className="password-login-form-button  form-button">立即登录</Button>
			</Form.Item>
			<Form.Item>
				<Button onClick={() => props.register()} style={{width: '50%'}}
				        className="code-register-form-button form-button">立即注册账号</Button>
				<Button onClick={() => {
					window.location.href = 'https://business.job.zhinanche.com/#/login'
				}} style={{width: '50%'}} className="code-register-form-button form-button">企业端登录</Button>
			</Form.Item>
		</Form>
	)
}

///注册模块
const RegisterHtml = (props) => {
	const [registerForm] = Form.useForm();
	let history = useHistory();
	const [loading, setLoading] = useState(false)
	const [time, setTime] = useState(60)
	const timeRef = useRef()//设置延时器

	useEffect(() => {
		//如果设置倒计时且倒计时不为0
		if (time && time !== 0)
			timeRef.current = setTimeout(() => {
				setTime(time => time - 1)
			}, 1000)
		if (time === 0) {
			setLoading(false)
			clearInterval(timeRef.current)
		}
		//清除延时器
		return () => {
			clearInterval(timeRef.current)
		}
	}, [time])
	//获取验证码
	const getCode = function () {
		const values = registerForm.getFieldsValue();
		if (values.username) {
			http.getCode({send: values.username}).then(res => {
				if (res.result === 1) {
					message.success('发送成功')
					setLoading(true)
					setTime(60)
					// form.setFieldsValue({
					//   code: res.data
					// })
				}
			})
		} else {
			message.info('请输入手机号');
		}
	}

	const putRegister = values => {
		http.register({
			params: {username: values.username, password: values.password},
			headersData: {send: values.username, code: values.code}
		}).then(res => {
			if (res.result === 1) {
				message.success('注册成功，返回登录')
				setTimeout(props.goBack(), 2000)
			}
		})
	}

	return (
		<div className="register-right account-box">
			<Menu className='login-tab' selectedKeys={['register']} mode="horizontal">
				<Menu.Item className='login-tab-item' key="register">新用户注册</Menu.Item>
			</Menu>
			<Form form={registerForm} onFinish={putRegister} name="normal_login" className="login-form">
				<Form.Item name="username" rules={[{required: true, len: 11, message: '请输入手机号'}]}>
					<Input placeholder="请输入手机号"/>
				</Form.Item>
				<Form.Item name="code">
					{/* rules={[{ required: true, message: 'Please input your Password!' }]} */}
					<Row className='code-input-row'>
						<Col span='16'><Input bordered={false} placeholder="请输入验证码"/></Col>
						{!loading ?
							<Col span='8'>
								<div onClick={getCode} className="get-code">获取验证码</div>
							</Col>
							:
							<Col span='8'>
								<div onClick={() => {
									message.error("验证码发送过于频繁，请等待")
								}} className="get-code">{time}秒后重新发送
								</div>
							</Col>
						}
					</Row>
				</Form.Item>
				<Form.Item name="password">
					<div className="code-input-row">
						<Input.Password className='pass-input' bordered={false} placeholder="请输入密码"/>
					</div>
				</Form.Item>
				<Form.Item className='login-btn'>
					<Button type="primary" htmlType="submit" className="regist-form-button  form-button">立即注册</Button>
				</Form.Item>
				<Form.Item>
					<Button onClick={() => {
						props.goBack()
					}} className="code-register-form-button form-button">已有账号，返回登录</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

///找回密码
const GetPasswordHtml = (props) => {
	const [getPasswordForm] = Form.useForm();
	let history = useHistory();
	const [loading, setLoading] = useState(false)
	const [time, setTime] = useState(60)
	const timeRef = useRef()//设置延时器

	useEffect(() => {
		//如果设置倒计时且倒计时不为0
		if (time && time !== 0)
			timeRef.current = setTimeout(() => {
				setTime(time => time - 1)
			}, 1000)
		if (time === 0) {
			setLoading(false)
			clearInterval(timeRef.current)
		}
		//清除延时器
		return () => {
			clearInterval(timeRef.current)
		}
	}, [time])
	//获取验证码
	const getCode = function () {
		const values = getPasswordForm.getFieldsValue();
		if (values.username) {
			http.getCode({send: values.username}).then(res => {

				if (res.result === 1) {
					message.success('发送成功')
					setLoading(true)
					setTime(60)
					// form.setFieldsValue({
					//   code: res.data
					// })
				}
			})
		} else {
			message.info('请输入手机号');
		}
	}

	const getPassword = values => {
		http.getPassword({
			params: {username: values.username, password: values.password},
			headersData: {send: values.username, code: values.code}
		}).then(res => {
			if (res.result === 1) {
				message.success('找回成功，返回登录')
				setTimeout(props.goBack(), 2000)
			}
		})
	}

	return (
		<div className="register-right account-box">
			<Menu className='login-tab' selectedKeys={['register']} mode="horizontal">
				<Menu.Item className='login-tab-item' key="register">找回密码</Menu.Item>
			</Menu>
			<Form form={getPasswordForm} onFinish={getPassword} name="normal_login" className="login-form">
				<Form.Item name="username" rules={[{required: true, len: 11, message: '请输入手机号'}]}>
					<Input placeholder="请输入手机号"/>
				</Form.Item>
				<Form.Item name="code">
					{/* rules={[{ required: true, message: 'Please input your Password!' }]} */}
					<Row className='code-input-row'>
						<Col span='16'><Input bordered={false} placeholder="请输入验证码"/></Col>
						{!loading ?
							<Col span='8'>
								<div onClick={getCode} className="get-code">获取验证码</div>
							</Col>
							:
							<Col span='8'>
								<div onClick={() => {
									message.error("验证码发送过于频繁，请等待")
								}} className="get-code">{time}秒后重新发送
								</div>
							</Col>
						}
					</Row>
				</Form.Item>
				<Form.Item name="password">
					<div className="code-input-row">
						<Input.Password className='pass-input' bordered={false} placeholder="请输入密码"/>
					</div>
				</Form.Item>
				<Form.Item className='login-btn'>
					<Button type="primary" htmlType="submit" className="regist-form-button  form-button">找回密码</Button>
				</Form.Item>
				<Form.Item>
					<Button htmlType="submit" onClick={() => props.goBack()}
					        className="code-register-form-button form-button">返回登录</Button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default class homePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			rediectToReferrer: false, // 是否重定向之前的界面
			current: 'code',  //默认是快捷登录
			loginFlag: 'login', //默认是登录页面
		};
	}

	componentDidMount() {
	}

	handleClick = e => {
		this.setState({current: e.key});
	};

	//切换到找回密码
	forgetPassword() {
		this.setState({loginFlag: 'getPass'})
	}

	//切换到注册账号
	register() {
		this.setState({loginFlag: 'register'})
	}

	//返回登录
	goback = () => {
		this.setState({loginFlag: 'login'})
	}

	//登录
	LoginHtml() {
		return (
			<div className="login-right account-box">
				<Menu className='login-tab' onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
					<Menu.Item className='login-tab-item' key="code">快捷登录</Menu.Item>
					<Menu.Item className='login-tab-item' key="password">密码登录</Menu.Item>
				</Menu>
				{this.state.current === 'code' ? <CodeHtml register={this.register.bind(this)}></CodeHtml> :
					<PasswordHtml register={this.register.bind(this)}
					              forgetPassword={this.forgetPassword.bind(this)}></PasswordHtml>}
			</div>
		);
	}

	render() {
		return (
			<div>
				<div className={localStorage.getItem("token") == null ? 'bg-img bg-img-left' : 'bg-img bg-img-center'}>
					<Row className='login-box'>
						<Col span='12' offset='12'>
							{
								localStorage.getItem("token") == null ?
									this.state.loginFlag === 'login' ? this.LoginHtml() :
										this.state.loginFlag === 'register' ? <RegisterHtml goBack={this.goback}></RegisterHtml> :
											<GetPasswordHtml goBack={this.goback}></GetPasswordHtml> : <div></div>
							}
						</Col>
					</Row>
				</div>
			</div>
		)
	}
}
