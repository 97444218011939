

// 根据生日时间戳计算年纪
const filterBirth = d => parseInt((new Date().getTime()-d)/1000/60/60/24/365);

/**
 * 日期格式化 年月日
 * @param Number time 时间戳
 * @param String format 格式
 */
 const dateFormat = (time, format) => {
    let date = new Date(time);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    let m = date.getMinutes();
    m = m < 10 ? ('0' + m) : m;
    let s = date.getSeconds();
    s = s < 10 ? ('0' + s) : s;
    if(format==='yyyy.MM.d h:m:s'){
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    }else{
      return y + '.' + MM + '.' + d;
    }
  }

  /**
 * 日期格式化 年月日 时分秒
 * @param Number time 时间戳
 * @param String format 格式
 */
//  const dateFormatDetail = (time, format) => {
//   let date = new Date(time);
//   let y = date.getFullYear();
//   let MM = date.getMonth() + 1;
//   MM = MM < 10 ? ('0' + MM) : MM;
//   let d = date.getDate();
//   d = d < 10 ? ('0' + d) : d;
//   let h = date.getHours();
//   h = h < 10 ? ('0' + h) : h;
//   let m = date.getMinutes();
//   m = m < 10 ? ('0' + m) : m;
//   let s = date.getSeconds();
//   s = s < 10 ? ('0' + s) : s;
//   return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
// }

// 跳转地址
// 本地
  // var url=`http://localhost:3000/#/jobDetail?job=`
  var JobURL=`#/jobDetail?job=`
  var CompanyURL=`#/companyDetail?company=`
// 测试
  // var JobURL=`http://test.zhinanche.com/job/#/jobDetail?job=`;
  // var CompanyURL=`http://test.zhinanche.com/job/#/companyDetail?company=`
// 线上
  // var url=`http://job.zhinanche.com/job//#/jobDetail?job=`
  // var JobURL=`http://job.zhinanche.com/#/jobDetail?job=`;
  // var CompanyURL=`http://job.zhinanche.com/#/companyDetail?company=`

//图片上传
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default{filterBirth,dateFormat,getBase64,JobURL,CompanyURL}
