import React, { Component } from 'react';
import { HashRouter as Router, Switch } from 'react-router-dom';
import routerMap from './routerMaps'
import RouterFuction from './routerFuction'

class BaseRouters extends Component {
    componentWillReceiveProps(nextProps) {
    if (nextProps.location.hash !== this.props.location.hash) {
        // window.leftMenu.forceUpdate(); //当路由不同时，强制更新左边栏组件
      }
    }
    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        <RouterFuction routerConfig={routerMap} {...this.props}/>
                    </Switch>
                </div>
            </Router>);
    }
}

export default BaseRouters;

