import {Col, Dropdown, Row, Menu, Avatar} from 'antd'
import React, {Component} from 'react'
import {Link,} from "react-router-dom";
import {
	LogoutOutlined, DownOutlined, InfoCircleOutlined
} from '@ant-design/icons';
import './header.less'

export default class header extends Component {
	state = {}

	navMenuClick() {
		localStorage.removeItem('token');
		localStorage.removeItem('userInfo');
	}

	loadJobList() {
		localStorage.setItem("keyWords", '');
		localStorage.setItem("city", '0');
		localStorage.setItem("defaultCity", '0,0');
	}

	render() {
		const menu = (
			<Menu className='drop-down' style={{'width': '140px'}}>
				<Menu.Item key="0" icon={<InfoCircleOutlined/>}>
					<Link to="/imformation">个人信息</Link>
				</Menu.Item>
				<Menu.Item key="1" icon={<img className='icon' src={require('./../../assets/menu-icon.png')}
				                              style={{'width': '12px', 'height': '12px'}} alt=""/>}>
					<Link to="/my">我的投递&收藏</Link>
				</Menu.Item>
				<Menu.Item key="2" icon={<LogoutOutlined/>} onClick={this.navMenuClick.bind(this)} danger>
					<Link to="/login">退出登录</Link>
				</Menu.Item>
			</Menu>
		);

		return (
			<div className='head'>
				<Row className='navRow' justify='center'>
					<Col span='4'>
						<img src={require('./../../assets/logo.png')} className='znc-logo'></img>
					</Col>
					<Col span={4}>
						<Link to={{pathname: '/login',}} className="page">
							<div>
								首页
							</div>
						</Link>
						<Link to={{pathname: '/searchPage'}} className="page">
							<div onClick={this.loadJobList}>
								职位
							</div>
						</Link>
					</Col>
					<Col span={12}>
					</Col>
					<Col span={4}>
						<div className='right'>
							{localStorage.getItem('token') ?
								<Dropdown overlay={menu} placement='bottom'>
									<a style={{'color': '#fff'}} onClick={e => e.preventDefault()}>
										{localStorage.getItem('userInfo') !== "" ? JSON.parse(localStorage.getItem('userInfo')).nick_name : '指南车用户'}
										<Avatar style={{marginLeft: '12px'}} size={32}
										        src={JSON.parse(localStorage.getItem('userInfo')).head_img}></Avatar>
									</a>

								</Dropdown> : <a style={{color: '#ffffff', fontWeight: '700'}}>客服电话：4008616755</a>}

						</div>
					</Col>
				</Row>
			</div>
		)
	}
}
