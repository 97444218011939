import React, { Component } from 'react'
import http from '../../http'
import './tag.less'
export default class softwareTag extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectlist: [],
            allWelfareList: []
        }
        this.getWelfare()
    }
    // handleSendPMsg = ()=>{
    //   this.props.getMsg(this.state.val)
    // }
    componentDidMount() {
        let data = [];
        http.tagSoftware().then((res)=>{
        res.data.map((e) => {
            e.select = false;
            if (this.props.selectWelfare!=[]) {
                this.props.selectWelfare.map((item) => {
                    if (e.name === item.name) {
                        e.select = true;
                    } 
                })
            }
            data.push(e)
        })
            this.setState({
                allWelfareList:data,
                selectlist: this.props.selectWelfare
            })
        })
    }

    getWelfare(){
       http.tagSoftware().then((res)=>{
           this.setState({
               allWelfareList:res.data
           })
       }) 
    }
    handleSendPMsg(index) {
        let data = this.state.allWelfareList;
        data[index].select = !data[index].select
        let selectData = []
        data.forEach(element => {
            if (element.select) {
                selectData.push(element.id)
            }
        });
        this.setState({
            allWelfareList: data,
            selectlist: selectData
        })
        this.props.postSelectWelfare(selectData)
    }
  render() {
    const selectStyle = { 'marginRight': '8px', 'marginBottom': '8px', 'borderColor': '#3188F3', 'color': '#3188F3' };
    return this.state.allWelfareList.map((e, index) => {
        return <li onClick={this.handleSendPMsg.bind(this, index)} className='welfare-li' style={e.select ? selectStyle : { 'marginRight': '8px', 'marginBottom': '8px' }} key={e.id}>{e.name}</li>
    })
  }
}