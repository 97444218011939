import {Col, Modal, Row, Upload, Input, Button, Avatar, message, Form, Select, DatePicker, InputNumber} from 'antd'
import React, {Component} from 'react'
import moment from 'moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Footer from '../footer/footer'
import Header from '../header/header'
import './personalInformation.less'
import common from '../../public/base'
import http from '../../http'
import {list} from 'postcss'
import FormItem from 'antd/lib/form/FormItem'
import ProfessionWidget from "./professionTag"
import SoftwareWidget from "./softwareTag"
import SkillWidget from "./skillTag"
import ApplyWidget from "./applyTag"
import BrandWidget from "./brandTag"
import {Link} from 'react-router-dom'
import HopePosition from './hopePosition'
import WorkEXP from './workEXP'
import ProjectEXP from './projectEXP'
import EduEXP from './eduEXP'
import myCollectionAndPostPage from '../myCollectionAndPost/myCollectionAndPostPage'

const {Option} = Select;

const {RangePicker} = DatePicker;

const {TextArea} = Input;


const beforeUpload = (file) => {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('请上传jpeg、png格式图片！');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('图片大小不超过2MB!');
	}
	return isJpgOrPng && isLt2M;
}

const beforeUpload2 = (file) => {
	//console.log(file.type);
	const isResumeFormat = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
		|| file.type === 'application/pdf' || file.type === 'image/jpg' || file.type === 'image/png'
		|| file.type === 'application/msword';
	if (!isResumeFormat) {
		message.error('请上传word、pdf或者jpg、png文件！');
	}
	// const isLt2M = file.size / 1024 / 1024 < 100;
	// if (!isLt2M) {
	//     message.error('图片大小不超过2MB!');
	// }
	return isResumeFormat
}

const job_status = ["离职-随时到岗", "在职-暂不考虑", "在职-考虑机会", "离职-月内到岗"]

export default class personalInformation extends Component {
	formRef1 = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			user: {
				name: "",
				head_img: "",
				birthday: 0,
				start_work: 0,
				phone: "",
				mail: "",
				education: {
					name: "",
				},
				resume: "",
				job_status: 0,
			},
			resume: [],
			resumeName: '',
			birthday: 0,
			educationID: 0,
			job_status: 0,
			imgURL: "",
			start_work: "",
			name: "",
			phone: "",
			mail: "",
			confirmLoading: false,
			allEducationList: [],
			informationModalVisible1: false,
			informationModalVisible6: false,
			informationModalVisible7: false,
			informationModalVisible8: false,
			informationModalVisible9: false,
			informationModalVisible10: false,
			professionList: [],
			softwareList: [],
			skillList: [],
			applyList: [],
			brandList: [],
			welfareData: [],
			provinceInfo: [],
			cityInfo: []
		};
		this.getEducation()
		this.getHopePosition()
		this.getWorkExperience()
		this.getProjectExperience()
		this.getEducationExperience()
		this.getProvinceInfo()
		this.getTagList()
		this.getUserInfo()
	}

	getProvinceInfo() {
		http.getMapInfo({id: 1}).then(res => {
			this.setState({
				provinceInfo: res.data.sub
			})
		})
	}

	getEducation() {
		http.getEducation().then((res) => {
			this.setState({
				allEducationList: res.data
			})
		})
	}

	getHopePosition() {
		http.getPositionHopeList().then((res) => {
			this.setState({
				hopeList: res.data
			})
		})
	}

	getWorkExperience() {
		http.getWorkExperienceList().then((res) => {
			this.setState({
				workList: res.data
			})
		})
	}

	getProjectExperience() {
		http.getProjectExperienceList().then((res) => {
			this.setState({
				projectList: res.data
			})
		})
	}

	getTagList() {
		var profession = []
		var software = []
		var skill = []
		var apply = []
		var brand = []
		http.getTagList().then((res) => {
			res.data.map((e) => {
				if (e.groups.search("profession") !== -1) {
					profession.push(e)
				}
				if (e.groups.search("software") !== -1) {
					software.push(e)
				}
				if (e.groups.search("skill") !== -1) {
					skill.push(e)
				}
				if (e.groups.search("apply") !== -1) {
					apply.push(e)
				}
				if (e.groups.search("brand") !== -1) {
					brand.push(e)
				}
			})
			this.setState({
				professionList: profession,
				softwareList: software,
				skillList: skill,
				applyList: apply,
				brandList: brand
			})
		})
	}

	getEducationExperience() {
		http.getEducationExperienceList().then((res) => {
			this.setState({
				educationList: res.data
			})
		})
	}

	showInformationModal1 = () => {
		this.setState({
			informationModalVisible1: true,
		})
	}

	getUserInfo() {
		http.getUserInfo().then(res2 => {
			//console.log(res2);
			localStorage.setItem('userInfo', JSON.stringify(res2.data));
			this.setState({
				user: res2.data,
				resumeName: res2.data.resume.slice(46)
			})
		})
	}

	informationModalOK1() {
		this.setState({
			confirmLoading: true
		})
		var params = {}
		if (this.state.name !== "") (
			params.name = this.state.name
		)
		if (this.state.imgURL !== "") (
			params.head_img = this.state.imgURL
		)
		if (this.state.phone !== "") (
			params.phone = this.state.phone
		)
		if (this.state.mail !== "") (
			params.mail = this.state.mail
		)
		if (this.state.start_work !== "") (
			params.start_work = this.state.start_work
		)
		if (this.state.birthday !== 0) (
			params.birthday = this.state.birthday
		)
		if (this.state.job_status !== 0) (
			params.job_status = this.state.job_status
		)
		if (this.state.educationID !== 0) (
			params.education = this.state.educationID
		)
		http.putUserInfo(params).then((res) => {
			if (res.result === 1) {
				message.success("修改成功")
				this.setState({
					informationModalVisible1: false,
					confirmLoading: false
				})
				this.getUserInfo()
				this.formRef1.current.resetFields()
			} else {
				message.warn("修改失败")
				this.setState({
					informationModalVisible1: false,
					confirmLoading: false
				})
				this.formRef1.current.resetFields()
			}
		})
	}


	informationModalcancle1 = () => {
		this.setState({
			informationModalVisible1: false,
		})
	}

	deleteResume() {
		http.putUserInfo({resume: ""}).then((res) => {
			if (res.result === 1) {
				message.success("删除成功")
				this.getUserInfo()
			} else {
				message.error("删除失败")
			}
		})
	}


	informationModalcancle6 = () => {
		this.setState({
			informationModalVisible6: false,
		})
		this.getTagList()
	}
	informationModalcancle7 = () => {
		this.setState({
			informationModalVisible7: false,
		})
		this.getTagList()
	}
	informationModalcancle8 = () => {
		this.setState({
			informationModalVisible8: false,
		})
		this.getTagList()
	}
	informationModalcancle9 = () => {
		this.setState({
			informationModalVisible9: false,
		})
		this.getTagList()
	}
	informationModalcancle10 = () => {
		this.setState({
			informationModalVisible10: false,
		})
		this.getTagList()
	}

	previewPDF() {
		let previewPDF = document.getElementById("pdf")
		let pdfNode = previewPDF.cloneNode(true)

		document.getElementById("ignore1").style.display = "none"
		// document.getElementById("ignore2").style.display="none"
		document.getElementById("ignore3").style.display = "none"
		document.getElementById("ignore4").style.display = "none"
		document.getElementById("ignore5").style.display = "none"
		document.getElementById("ignore6").style.display = "none"
		document.getElementById("ignore7").style.display = "none"
		document.getElementById("ignore8").style.display = "none"
		document.getElementById("ignore9").style.display = "none"
		document.getElementById("ignore10").style.display = "none"
		document.getElementById("ignore11").style.display = "none"
		// ignore.parentNode.removeChild(ignore)
		html2canvas(document.getElementById("pdf"), {
			// height: document.getElementById("Pdf").offsetHeight,
			background: "#fff",
			useCORS: true,
			logging: false,
			allowTaint: true,
			onrendered: function (canvas) {
			}
		}).then(function (canvas) {
			let contentWidth = canvas.width
			let contentHeight = canvas.height
			let pageHeight = contentWidth / 592.28 * 841.89
			let position = 20
			let imgWidth = 595.28
			let imgHeight = 592.28 / contentWidth * contentHeight
			let pageData = canvas.toDataURL('image/jpeg', 1.0).replace("image/png", "image/octet-stream");
			let pdf = new jsPDF('', 'pt', 'a4')
			if (contentHeight < pageHeight) {
				pdf.addImage(pageData, 'JPEG', 0, 20, imgWidth, imgHeight);
			} else {
				pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
				while (contentHeight > 0) {
					pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
					contentHeight -= pageHeight;
					position -= 841.89;
					//避免添加空白页
					if (contentHeight > 0) {
						pdf.addPage();
					}
				}
			}
			document.getElementById("ignore1").style.display = "flex"
			// document.getElementById("ignore2").style.display="flex"
			document.getElementById("ignore3").style.display = "flex"
			document.getElementById("ignore4").style.display = "flex"
			document.getElementById("ignore5").style.display = "flex"
			document.getElementById("ignore6").style.display = "flex"
			document.getElementById("ignore7").style.display = "flex"
			document.getElementById("ignore8").style.display = "flex"
			document.getElementById("ignore9").style.display = "flex"
			document.getElementById("ignore10").style.display = "flex"
			document.getElementById("ignore11").style.display = "flex"
			var string = pdf.output("datauristring")
			var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
			var x = window.open()
			x.document.open()
			x.document.write(iframe)
			x.document.close()
			// pdf.save('w的简历' + '.pdf');
		})
	}

	onChange(date, dateString, v) {
		this.setState({
			start_work: moment(date).valueOf(),
		})
	}

	onChange2(v) {
		this.setState({
			birthday: moment(v).valueOf(),
		})
	}

	handleChange(value) {
		this.setState({
			educationID: value
		})
	}

	handleChange2(value) {
		this.setState({
			job_status: value + 1
		})
	}

	handleChangeBusiness = info => {
		if (info.file.status === 'uploading') {
			// setLoading(true)
		}
		if (info.file.status === 'done') {
			common.getBase64(info.file.originFileObj, imageUrl => {
					// setLoading(false)
					// setLicenseImg(info.file.response.data)
					this.setState({
						imgURL: info.file.response.data
					})
				}
			);
		}
		// setFileList([...info.fileList]);
	};
	handleChangeResume = info => {
		if (info.file.status === 'uploading') {
			// setLoading(true)
		}
		if (info.file.status === 'done') {
			//console.log(info.file);
			common.getBase64(info.file.originFileObj, imageUrl => {
					// setLoading(false)
					// setLicenseImg(info.file.response.data)
					http.putUserInfo({resume: info.file.response.data}).then((res) => {
						if (res.result === 1) {
							message.success("上传成功")
							this.getUserInfo()
						} else {
							message.error("上传失败")
						}
					})
					this.setState({
						resume: info.file.response.data,
						resumeName: info.file.name
					})

				}
			)
			http.putUserInfo({resume: this.state.resume})
		}
	};

	addProfession() {
		this.setState({
			informationModalVisible6: true
		})
	}

	addSoftware() {
		this.setState({
			informationModalVisible7: true
		})
	}

	addSkill() {
		this.setState({
			informationModalVisible8: true
		})
	}

	addApply() {
		this.setState({
			informationModalVisible9: true
		})
	}

	addBrand() {
		this.setState({
			informationModalVisible10: true
		})
	}

	postSelectProfession(res) {
		// selectWelfare = res
		// setSelectWelfare(res)
		this.setState({
			professionList: res
		})
	}

	postSelectSoftware(res) {
		// selectWelfare = res
		// setSelectWelfare(res)
		this.setState({
			softwareList: res
		})
	}

	postSelectSkill(res) {
		// selectWelfare = res
		// setSelectWelfare(res)
		this.setState({
			skillList: res
		})
	}

	postSelectApply(res) {
		// selectWelfare = res
		// setSelectWelfare(res)
		this.setState({
			applyList: res
		})
	}

	postSelectBrand(res) {
		// selectWelfare = res
		// setSelectWelfare(res)
		this.setState({
			brandList: res
		})
	}

	handleOk1() {
		var professionList = this.state.professionList
		var softWareList = []
		var skillList = []
		var applyList = []
		var brandList = []
		// var tagList=[...this.state.professionList,...this.state.softwareList,
		//   ...this.state.applyList,...this.state.brandList,...this.state.skillList]
		this.state.softwareList.map((e) => {
			softWareList.push(e.id)
		})
		this.state.skillList.map((e) => {
			skillList.push(e.id)
		})
		this.state.applyList.map((e) => {
			applyList.push(e.id)
		})
		this.state.brandList.map((e) => {
			brandList.push(e.id)
		})
		var tagList = []
		tagList.push(...professionList, ...softWareList, ...skillList, ...applyList, ...brandList)
		var param = {
			tag: tagList
		}
		http.updataTag(param).then((res) => {
			if (res.result === 1) {
				message.success("修改成功")
				this.setState({
					informationModalVisible6: false
				})
				this.getTagList()
			} else {
				message.warn("网络错误")
			}
		})
	}

	handleOk2() {
		var professionList = []
		var softWareList = this.state.softwareList
		var skillList = []
		var applyList = []
		var brandList = []
		// var tagList=[...this.state.professionList,...this.state.softwareList,
		//   ...this.state.applyList,...this.state.brandList,...this.state.skillList]
		this.state.professionList.map((e) => {
			professionList.push(e.id)
		})
		this.state.skillList.map((e) => {
			skillList.push(e.id)
		})
		this.state.applyList.map((e) => {
			applyList.push(e.id)
		})
		this.state.brandList.map((e) => {
			brandList.push(e.id)
		})
		var tagList = []
		tagList.push(...professionList, ...softWareList, ...skillList, ...applyList, ...brandList)
		var param = {
			tag: tagList
		}
		http.updataTag(param).then((res) => {
			if (res.result === 1) {
				message.success("修改成功")
				this.setState({
					informationModalVisible7: false
				})
				this.getTagList()
			} else {
				message.warn("网络错误")
			}
		})
	}

	handleOk3() {
		var professionList = []
		var softWareList = []
		var skillList = this.state.skillList
		var applyList = []
		var brandList = []
		this.state.professionList.map((e) => {
			professionList.push(e.id)
		})
		this.state.softwareList.map((e) => {
			softWareList.push(e.id)
		})
		this.state.applyList.map((e) => {
			applyList.push(e.id)
		})
		this.state.brandList.map((e) => {
			brandList.push(e.id)
		})
		var tagList = []
		tagList.push(...professionList, ...softWareList, ...skillList, ...applyList, ...brandList)
		var param = {
			tag: tagList
		}
		http.updataTag(param).then((res) => {
			if (res.result === 1) {
				message.success("修改成功")
				this.setState({
					informationModalVisible8: false
				})
				this.getTagList()
			} else {
				message.warn("网络错误")
			}
		})
	}

	handleOk4() {
		var professionList = []
		var softWareList = []
		var skillList = []
		var applyList = this.state.applyList
		var brandList = []
		// var tagList=[...this.state.professionList,...this.state.softwareList,
		//   ...this.state.applyList,...this.state.brandList,...this.state.skillList]
		this.state.professionList.map((e) => {
			professionList.push(e.id)
		})
		this.state.softwareList.map((e) => {
			softWareList.push(e.id)
		})
		this.state.skillList.map((e) => {
			skillList.push(e.id)
		})
		this.state.brandList.map((e) => {
			brandList.push(e.id)
		})
		var tagList = []
		tagList.push(...professionList, ...softWareList, ...skillList, ...applyList, ...brandList)
		var param = {
			tag: tagList
		}
		http.updataTag(param).then((res) => {
			if (res.result === 1) {
				message.success("修改成功")
				this.setState({
					informationModalVisible9: false
				})
				this.getTagList()
			} else {
				message.warn("网络错误")
			}
		})
	}

	handleOk5() {
		var professionList = []
		var softWareList = []
		var skillList = []
		var applyList = []
		var brandList = this.state.brandList
		// var tagList=[...this.state.professionList,...this.state.softwareList,
		//   ...this.state.applyList,...this.state.brandList,...this.state.skillList]
		this.state.professionList.map((e) => {
			professionList.push(e.id)
		})
		this.state.softwareList.map((e) => {
			softWareList.push(e.id)
		})
		this.state.skillList.map((e) => {
			skillList.push(e.id)
		})
		this.state.applyList.map((e) => {
			applyList.push(e.id)
		})
		var tagList = []
		tagList.push(...professionList, ...softWareList, ...skillList, ...applyList, ...brandList)
		var param = {
			tag: tagList
		}
		http.updataTag(param).then((res) => {
			if (res.result === 1) {
				message.success("修改成功")
				this.setState({
					informationModalVisible10: false
				})
				this.getTagList()
			} else {
				message.warn("网络错误")
			}
		})
	}

	openDoc() {
		var a = this.state.user.resume
		const w = window.open('about:blank');
		w.location.href = a
	}

	render() {
		var user = this.state.user
		return (
			<div>
				<Header></Header>
				<div className="middle">
					<div className="center">
						<Row>
							<Col>
								<div className='I-first-col'>
									<div className='preview-box'>
										<Row justify='space-between'>
											<Col>
												<div className='title-box'>
													<div className='title-header'>
														我的在线简历
													</div>
												</div>
											</Col>
											<Col>
												<div className='preview' onClick={this.previewPDF}>
													预览简历
												</div>
											</Col>
										</Row>
									</div>
									<div className='title-divider'>
									</div>
									<div id="pdf" className='resume-box'>
										{/* 基本信息 */}
										<Row>
											<div className='information'>
												<div className='avater'>
													<Avatar size={88} src={user.head_img}/>
												</div>
												<div className='detail-box'>
													<div className='detail-1'>
														<div className='name'>
															{user.name !== "" ? user.name : "未设置"}
														</div>
														<div id='ignore1' className='edit' onClick={this.showInformationModal1}>
															<img className='icon' src={require('./../../assets/edit-icon.png')} alt=""/>
															编辑
														</div>
													</div>
													<div className='character33'>
														<img className='icon-2' src={require('./../../assets/experience-icon.png')} alt=""/>
														{user.start_work === null ? "未设置" : common.filterBirth(user.start_work) + "年工作经验"}&nbsp;&nbsp;|&nbsp;&nbsp;
														<img className='icon-2' src={require('./../../assets/education-icon.png')} alt=""/>
														{user.education.name === "" ? "未设置" : user.education.name}&nbsp;&nbsp;|&nbsp;&nbsp;
														<img className='icon-2' src={require('./../../assets/job-status-icon.png')} alt=""/>
														{user.job_status === null ? "未设置" : ["离职-随时到岗", "在职-暂不考虑", "在职-考虑机会", "离职-月内到岗"][user.job_status - 1]}
													</div>
													<div className='character33'>
														<img className='icon-2' src={require('./../../assets/time-icon.png')} alt=""/>
														{user.birthday === null ? "未设置" : common.dateFormat(user.birthday)}&nbsp;&nbsp;|&nbsp;&nbsp;
														<img className='icon-2' src={require('./../../assets/tel-icon.png')} alt=""/>
														{user.phone == null ? "未设置" : user.phone}&nbsp;&nbsp;|&nbsp;&nbsp;
														<img className='icon-2' src={require('./../../assets/email-icon.png')} alt=""/>
														{user.mail == null ? "未设置" : user.mail}
													</div>
												</div>
											</div>
										</Row>
										<div className='divider'></div>
										{/* 期望职位 */}
										<HopePosition></HopePosition>
										<div className='divider'></div>
										{/* 工作经历 */}
										<WorkEXP></WorkEXP>
										<div className='divider'></div>
										{/* 项目经历 */}
										<ProjectEXP></ProjectEXP>
										<div className='divider'></div>
										{/* 教育经历 */}
										<EduEXP></EduEXP>
										<div className='divider'></div>
										<Row className='title-head' justify='space-between'>
											<Col>
												<Row>
													<div className='title-logo'>

													</div>
													<div className='title-character'>
														擅长专业
													</div>
												</Row>
											</Col>
											<Col>
												<div id='ignore7' className='edit' onClick={this.addProfession.bind(this)}>
													<img className='icon' src={require('./../../assets/edit-icon.png')} alt=""/>
													编辑
												</div>
											</Col>
										</Row>
										<Row>
											<div className='item-box'>
												<div className='item'>
													<Row>
														<div className='skill-box'>
															{
																this.state.professionList.map((e) => {
																	return (
																		<div className="skill" key={e.id}>
																			{e.name}
																		</div>
																	)
																})
															}
														</div>
													</Row>
												</div>
											</div>
										</Row>
										<div className='divider'></div>
										<Row className='title-head' justify='space-between'>
											<Col>
												<Row>
													<div className='title-logo'>

													</div>
													<div className='title-character'>
														专业软件
													</div>
												</Row>
											</Col>
											<Col>
												<div id='ignore8' className='edit' onClick={this.addSoftware.bind(this)}>
													<img className='icon' src={require('./../../assets/edit-icon.png')} alt=""/>
													编辑
												</div>
											</Col>
										</Row>
										<Row>
											<div className='item-box'>
												<div className='item'>
													<Row>
														<div className='skill-box'>
															{
																this.state.softwareList.map((e) => {
																	return (
																		<div className="skill" key={e.id}>
																			{e.name}
																		</div>
																	)
																})
															}
														</div>
													</Row>
												</div>
											</div>
										</Row>
										<div className='divider'></div>
										<Row className='title-head' justify='space-between'>
											<Col>
												<Row>
													<div className='title-logo'>

													</div>
													<div className='title-character'>
														熟练技能
													</div>
												</Row>
											</Col>
											<Col>
												<div id='ignore9' className='edit' onClick={this.addSkill.bind(this)}>
													<img className='icon' src={require('./../../assets/edit-icon.png')} alt=""/>
													编辑
												</div>
											</Col>
										</Row>
										<Row>
											<div className='item-box'>
												<div className='item'>
													<Row>
														<div className='skill-box'>
															{
																this.state.skillList.map((e) => {
																	return (
																		<div className="skill" key={e.id}>
																			{e.name}
																		</div>
																	)
																})
															}
														</div>
													</Row>
												</div>
											</div>
										</Row>
										<div className='divider'></div>
										<Row className='title-head' justify='space-between'>
											<Col>
												<Row>
													<div className='title-logo'>

													</div>
													<div className='title-character'>
														擅长应用
													</div>
												</Row>
											</Col>
											<Col>
												<div id='ignore10' className='edit' onClick={this.addApply.bind(this)}>
													<img className='icon' src={require('./../../assets/edit-icon.png')} alt=""/>
													编辑
												</div>
											</Col>
										</Row>
										<Row>
											<div className='item-box'>
												<div className='item'>
													<Row>
														<div className='skill-box'>
															{
																this.state.applyList.map((e) => {
																	return (
																		<div className="skill" key={e.id}>
																			{e.name}
																		</div>
																	)
																})
															}
														</div>
													</Row>
												</div>
											</div>
										</Row>
										<div className='divider'></div>
										<Row className='title-head' justify='space-between'>
											<Col>
												<Row>
													<div className='title-logo'>

													</div>
													<div className='title-character'>
														擅长品牌
													</div>
												</Row>
											</Col>
											<Col>
												<div id='ignore11' className='edit' onClick={this.addBrand.bind(this)}>
													<img className='icon' src={require('./../../assets/edit-icon.png')} alt=""/>
													编辑
												</div>
											</Col>
										</Row>
										<Row>
											<div className='item-box'>
												<div className='item'>
													<Row>
														<div className='skill-box'>
															{
																this.state.brandList.map((e) => {
																	return (
																		<div className="skill" key={e.id}>
																			{e.name}
																		</div>
																	)
																})
															}
														</div>
													</Row>
												</div>
											</div>
										</Row>
									</div>
								</div>
							</Col>
							<Col>
								<div className='I-second-col'>
									<div className='resume-doc'>
										<div className='doc-header'>
											附件简历
										</div>
										<div>
											{
												user.resume === "" ?
													(
														<div className="doc">
															<div className='doc-name'>
																暂无简历
															</div>
														</div>
													)
													:
													(
														<div className="doc">
															<div className='doc-name' onClick={this.openDoc.bind(this)}>
																{this.state.resumeName}
															</div>
															<img className='icon' src={require('./../../assets/del-2-icon.png')} alt=""
															     onClick={this.deleteResume.bind(this)}/>
														</div>
													)
											}
											<Upload
												name="file"
												// listType="picture"
												className="avatar-uploader"
												showUploadList={false}
												action={http.baseUrl + "/common/file/upload/resume"}
												beforeUpload={beforeUpload2}

												onChange={this.handleChangeResume}
												data={file => ({// file 是当前正在上传的图片
													file_name: file.size + file.name.replace(/\s/g, '')
												})}
												// fileList={this.state.resume}
												maxCount={1}
												headers={{'id': 0,}}>
												<Button type='primary'
												        style={{
													        width: '268px',
													        marginTop: '16px',
													        borderRadius: '3px'
												        }}>上传简历</Button>
												<div style={{
													marginTop: '4px',
													fontSize: '12px',
													color: '#828282',
													textAlign: 'center'
												}}>仅支持doc、docx、pdf、jpg、png格式的文件
												</div>
											</Upload>
										</div>
									</div>
									<div className='my'>
										<Link to={{
											pathname: "my",
											state: {
												tab: "2"
											}
										}}>
											<div className='my-item'>
												<div className='my-name'>
													我的收藏
												</div>
												<img className='right-icon' src={require('./../../assets/right-icon.png')} alt=''/>
											</div>
										</Link>
										<div className='my-divide'></div>
										<Link to={{
											pathname: "my",
											state: {
												tab: "1"
											}
										}}>
											<div className='my-item'>
												<div className='my-name'>
													我的投递
												</div>
												<img className='right-icon' src={require('./../../assets/right-icon.png')} alt=''/>
											</div>
										</Link>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<Footer></Footer>

				<Modal title="编辑基本信息" visible={this.state.informationModalVisible1}
				       confirmLoading={this.state.confirmLoading}
				       onOk={this.informationModalOK1.bind(this)} onCancel={this.informationModalcancle1}>
					<div>
						<Form name='information' ref={this.formRef1}>
							<FormItem label="头&emsp;&emsp;&emsp;&emsp;像" name={"avatar"}>
								<Upload
									name="file"
									listType="picture-card"
									className="avatar-uploader"
									showUploadList={true}
									action={http.baseUrl + "/common/file/upload/img"}
									beforeUpload={beforeUpload}
									onChange={this.handleChangeBusiness}
									data={file => ({// file 是当前正在上传的图片
										file_name: file.size + file.name.replace(/\s/g, '')
									})}
									// fileList={fileList}
									maxCount={1}
									headers={{'id': 0,}}
								>
									{/* {this.state.user.head_img ? <Avatar size={88} src={this.state.user.head_img}/>: "上传头像"}  */}
									{this.state.user.head_img ? <Avatar size={88} src={this.state.user.head_img}/> : "上传头像"}
								</Upload>
								{/* {this.state.user.head_img ? <Avatar size={88} src={this.state.user.head_img}/>: "上传头像"} */}
							</FormItem>
							<FormItem label="姓&emsp;&emsp;&emsp;&emsp;名" name="name">
								<Input placeholder='请填写姓名'
								       defaultValue={this.state.user.name}
								       onChange={e => {
									       this.setState(state => {
										       state.name = e.target.value
									       })
								       }}/>
							</FormItem>
							<FormItem label="开始工作时间" name={"start_work"}>
								<DatePicker placeholder="请选择开始工作日期"
								            defaultValue={this.state.user.start_work === null || this.state.user.start_work === 0 ? null : moment(this.state.user.start_work)}
								            onChange={(v) => (this.onChange(v))}
								            picker="month"
								            style={{width: 160}}
								/>
							</FormItem>
							<FormItem label="学&emsp;&emsp;&emsp;&emsp;历" name={"education"}>
								<Select placeholder="请选择学历"
								        defaultValue={this.state.user.education.name === null || this.state.user.education.name === "" ? null : this.state.user.education.name}
								        style={{width: 160}}
								        onChange={this.handleChange.bind(this)}>
									{
										this.state.allEducationList.map(e => (
											<Option key={e.name} value={e.id}>{e.name}</Option>
										))
									}
								</Select>
							</FormItem>
							<FormItem label="求&ensp;职&emsp;状&ensp;态" name={"job-status"}>
								<Select placeholder="求职状态"
								        defaultValue={this.state.user.job_status === null || this.state.user.job_status === 0 ? null : job_status[this.state.user.job_status - 1]}
								        style={{width: 160}}
								        onChange={this.handleChange2.bind(this)}>
									{
										job_status.map((e, index) => (
											<Option key={e} value={index}>{e} </Option>
										))
									}
								</Select>
							</FormItem>
							<FormItem label="出&ensp;生&emsp;日&ensp;期" name={"birthday"}>
								<DatePicker placeholder="请选择出生日期"
								            defaultValue={this.state.user.birthday == null ? null : moment(this.state.user.birthday)}
								            onChange={(v) => {
									            this.onChange2(v)
								            }}
								            picekermode="date"
								            style={{width: 160}}/>
							</FormItem>
							<FormItem label="电&emsp;&emsp;&emsp;&emsp;话">
								<Input placeholder={this.state.user.phone === "" ? '请输入电话号码' : this.state.user.phone} disabled={true}
								       onChange={e => {
									       this.setState(state => {
										       state.phone = e.target.value
									       })
								       }}/>
							</FormItem>
							<FormItem label="邮&emsp;&emsp;&emsp;&emsp;箱" name={"email"}>
								<Input placeholder='请输入电子邮箱'
								       defaultValue={this.state.user.mail}
								       onChange={e => {
									       this.setState({mail: e.target.value})
								       }}/>
							</FormItem>
						</Form>
					</div>
				</Modal>
				<Modal width={700} className='welfare-box' cancelText='取消' okText='确定' title="擅长专业"
				       visible={this.state.informationModalVisible6} onOk={() => this.handleOk1()}
				       onCancel={this.informationModalcancle6}>
					<ProfessionWidget postSelectWelfare={(index) => {
						this.postSelectProfession(index)
					}} selectWelfare={this.state.professionList ? this.state.professionList : []}
					                  welfareList={this.state.professionList}></ProfessionWidget>
				</Modal>
				<Modal width={700} className='welfare-box' cancelText='取消' okText='确定' title="擅长软件"
				       visible={this.state.informationModalVisible7} onOk={() => this.handleOk2()}
				       onCancel={this.informationModalcancle7}>
					<SoftwareWidget postSelectWelfare={(index) => {
						this.postSelectSoftware(index)
					}} selectWelfare={this.state.software ? this.state.software : []}
					                welfareList={this.state.software}></SoftwareWidget>
				</Modal>
				<Modal width={700} className='welfare-box' cancelText='取消' okText='确定' title="擅长技能"
				       visible={this.state.informationModalVisible8} onOk={() => this.handleOk3()}
				       onCancel={this.informationModalcancle8}>
					<SkillWidget postSelectWelfare={(index) => {
						this.postSelectSkill(index)
					}} selectWelfare={this.state.skillList ? this.state.skillList : []}
					             welfareList={this.state.skillList}></SkillWidget>
				</Modal>
				<Modal width={700} className='welfare-box' cancelText='取消' okText='确定' title="擅长应用"
				       visible={this.state.informationModalVisible9} onOk={() => this.handleOk4()}
				       onCancel={this.informationModalcancle9}>
					<ApplyWidget postSelectWelfare={(index) => {
						this.postSelectApply(index)
					}} selectWelfare={this.state.applyList ? this.state.applyList : []}
					             welfareList={this.state.applyList}></ApplyWidget>
				</Modal>
				<Modal width={700} className='welfare-box' cancelText='取消' okText='确定' title="擅长品牌"
				       visible={this.state.informationModalVisible10} onOk={() => this.handleOk5()}
				       onCancel={this.informationModalcancle10}>
					<BrandWidget postSelectWelfare={(index) => {
						this.postSelectBrand(index)
					}} selectWelfare={this.state.brandList ? this.state.brandList : []}
					             welfareList={this.state.brandList}></BrandWidget>
				</Modal>
			</div>
		)
	}
}
