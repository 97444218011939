import React, { Component } from 'react'
import './hotCompany.less'
import HotCompanyCard from './hotCompanyCard'
import http from '../../http'

export default class hotCompany extends Component {
  constructor(props){
    super(props)
    this.state={
      hotCompany:[]
    }
  }

  componentWillMount(){
    let params={
      _b: 1,
      _e: 10
    }
    http.hotCompany(params).then(res=>{
      if(res.result===1){
        this.setState({
          hotCompany:res.data
        })
      }
    })
  }
  render() {
    return (
      <div className='hot-company'>
          <div className='header'>
                名企推荐
          </div>
          <div className='card-box'>
            {
              this.state.hotCompany.map((e,index)=>{
                if(index<10){
                return (
                  <div key={e.id}>
                    <div className='divide'/>
                    <div className='card'>
                      <HotCompanyCard key={e.id} item={e}/>
                    </div>
                  </div>
                )
                }
              })
            }
          </div>
      </div>
    )
  }
}
