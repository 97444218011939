import {Col, Row} from 'antd'
import React, {Component} from 'react'
import './searchPageJobCard.less'
import http from '../../http'
import common from '../../public/base'

export default class searchPageJobCard extends Component {
	constructor(props) {
		super(props)
	}

	goDetail() {
		const w = window.open('about:blank');
		w.location.href = common.JobURL + this.props.job.id;
	}

	render() {
		const job = this.props.job
		return (
			<div className='searchPageJobCard' onClick={this.goDetail.bind(this)}>
				<div className='spjc-detail'>
					<Row>
						<Col span={12}>
							<Row>
								<div className='job'>
									{job.name}
								</div>
							</Row>
							<Row>
								<div className='salary'>
									{job.salary.name.replace('"', '')}
								</div>
								<div className='detail'>
									{job.address.city.replace(/"/g, '')}&nbsp;&nbsp;
									|&nbsp;&nbsp;
									{job.work_years.name.replace(/"/g, '')}&nbsp;&nbsp;
									|&nbsp;&nbsp;
									{job.education.name.replace(/"/g, '')}
								</div>
							</Row>
						</Col>
						<Col span={8}>
							<Row>
								<div className='company-name'>
									{job.company.name}
								</div>
							</Row>
							<Row>
								<div className='company-detail'>
									{job.company.scale.name}&nbsp;&nbsp;|&nbsp;&nbsp;{job.company.address.city}
								</div>
							</Row>
						</Col>
						<Col span={4}>
							<div className='company-logo'>
								<img src={job.company.logo} ></img>
							</div>
						</Col>
					</Row>
				</div>
				<div className='spjc-welfare'>
					{
						job.welfare.map((e, index) => {
							return (
								<div className='welfare' key={e.id}>
									{e.name.replace('"', '')}
								</div>
							)
						})
					}
					{
						this.props.type === 'search' ? (<div></div>)
							:
							this.props.type === 'post' ? (
								<div className='time'>
									投递于&nbsp;{common.dateFormat(job.update_time)}
								</div>
							) : (
								<div className='time'>
									收藏于&nbsp;{common.dateFormat(job.update_time)}
								</div>
							)
					}

				</div>
			</div>
		)
	}
}
                                                