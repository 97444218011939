import React, { Component } from 'react'
import { Col, Modal, Row, Upload, Input, Button, Avatar, message, Form, Select, DatePicker, InputNumber } from 'antd'
import http from '../../http'
import FormItem from 'antd/lib/form/FormItem'
import common from '../../public/base'
import moment from 'moment'

const { Option } = Select;

const { RangePicker } = DatePicker;
export default class eduEXP extends Component {
    constructor(props) {
        super(props)
        this.state = {
            confirmLoading: false,
            imformationModalVisible5: false,
            allEducationList: [],
            educationList: [],
            eduEXP: {
                id: 0,
                name: "",
                education: 0,
                profession: "",
                start_time: 0,
                end_time: 0,
                education_name:""
            },
        }
        this.getEducation()
        this.getEducationExperience()
    }

    getEducation() {
        http.getEducation().then((res) => {
            this.setState({
                allEducationList: res.data
            })
        })
    }

    getEducationExperience() {
        http.getEducationExperienceList().then((res) => {
            this.setState({
                educationList: res.data
            })
        })
    }

    imformationModalOK5() {
        this.setState({
            confirmLoading: true
        })
        var params = {}
        var flag = true
        if (this.state.eduEXP.name != "") {
            params.name = this.state.eduEXP.name
        } else {
            message.info("请输入学校名称")
            flag = false
        }
        if (this.state.eduEXP.profession != "") {
            params.profession = this.state.eduEXP.profession
        } else {
            message.info("请输入所学专业")
            flag = false
        }
        if (this.state.eduEXP.education != 0) {
            params.education = this.state.eduEXP.education
        } else {
            message.info("请选择学位")
            flag = false
        }
        if (this.state.eduEXP.start_time != 0) {
            params.start_time = this.state.eduEXP.start_time
        } else {
            message.info("请选择开始学习时间")
            flag = false
        }
        if (this.state.eduEXP.end_time != 0) {
            params.end_time = this.state.eduEXP.end_time
        } else {
            message.info("请选择结束学习时间")
            flag = false
        }
        if (flag) {
            if (this.state.eduEXP.id == "") {
                http.addEducationExperienceList(params).then((res) => {
                    if (res.result == 1) {
                        message.success("修改成功")
                        this.setState(state => {
                            state.imformationModalVisible5 = false
                            state.confirmLoading = false
                            state.eduEXP.id = 0
                            state.eduEXP.name = ""
                            state.eduEXP.education = 0
                            state.eduEXP.education_name = ""
                            state.eduEXP.profession = ""
                            state.eduEXP.start_time = 0
                            state.eduEXP.end_time = 0
                        })
                        this.getEducationExperience()
                        this.formRef5.current.resetFields()
                    } else {
                        message.warn("修改失败")
                        this.setState(state => {
                            state.imformationModalVisible5 = false
                            state.confirmLoading = false
                            state.eduEXP.id = 0
                            state.eduEXP.name = ""
                            state.eduEXP.education = 0
                            state.eduEXP.education_name = ""
                            state.eduEXP.profession = ""
                            state.eduEXP.start_time = 0
                            state.eduEXP.end_time = 0
                        })
                        this.formRef5.current.resetFields()
                    }
                })
            } else {
                var id = this.state.eduEXP.id
                http.updataEducationExperiencenList(id, params).then((res) => {
                    if (res.result == 1) {
                        message.success("修改成功")
                        this.setState(state => {
                            state.imformationModalVisible5 = false
                            state.confirmLoading = false
                            state.eduEXP.id = 0
                            state.eduEXP.name = ""
                            state.eduEXP.education = 0
                            state.eduEXP.education_name = ""
                            state.eduEXP.profession = ""
                            state.eduEXP.start_time = 0
                            state.eduEXP.end_time = 0
                        })
                        this.getEducationExperience()
                        this.formRef5.current.resetFields()
                    } else {
                        message.warn("修改失败")
                        this.setState(state => {
                            state.imformationModalVisible5 = false
                            state.confirmLoading = false
                            state.eduEXP.id = 0
                            state.eduEXP.name = ""
                            state.eduEXP.education = 0
                            state.eduEXP.education_name = ""
                            state.eduEXP.profession = ""
                            state.eduEXP.start_time = 0
                            state.eduEXP.end_time = 0
                        })
                        this.formRef5.current.resetFields()
                    }
                })
            }
        } else {
            this.setState({
                confirmLoading: false
            })
        }
    }

    editEduEXP(e) {
        this.setState({
            eduEXP: e
        }, () => { this.showInformationModal5() })
    }

    deleteEduEXP(e) {
        http.deleteEducationExperiencenList(e.id).then((res) => {
            if (res.result == 1) {
                message.success("删除成功")
                this.getEducationExperience()
            } else {
                message.warn("删除失败")
            }
        })
    }

    imformationModalcancle5 = () => {
        this.setState({
            imformationModalVisible5: false,
            confirmLoading: false
        })
        this.setState(state => {
            state.imformationModalVisible5 = false
            state.confirmLoading = false
            state.eduEXP.id = 0
            state.eduEXP.name = ""
            state.eduEXP.education = 0
            state.eduEXP.education_name = ""
            state.eduEXP.profession = ""
            state.eduEXP.start_time = 0
            state.eduEXP.end_time = 0
        })
        this.getEducationExperience()
        this.formRef5.current.resetFields()
    }

    onChange5(date1) {
        this.setState(state => {
            state.eduEXP.start_time = moment(date1[0]).valueOf()
            state.eduEXP.end_time = moment(date1[1]).valueOf()
        })
    }

    handleChange3(value) {
        this.setState(state => {
            state.eduEXP.education = value
        })
    }

    showInformationModal5 = () => {
        this.setState({
            imformationModalVisible5: true,
        })
    }
    render() {
        return (
            <div>
                <Row className='title-head' justify='space-between'>
                    <Col>
                        <Row>
                            <div className='title-logo'>

                            </div>
                            <div className='title-character'>
                                教育经历
                            </div>
                        </Row>
                    </Col>
                    <Col>
                        <div id='ignore6' className='edit' onClick={this.showInformationModal5}>
                            <img className='icon' src={require('./../../assets/add-icon.png')} alt="" />
                            添加
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className='item-box'>
                        {
                            this.state.educationList == null ?
                                (
                                    <div></div>
                                )
                                :
                                (
                                    this.state.educationList.map((e) => {
                                        return (
                                            <div className='edit-box' key={e.id}>
                                                <div className='item'>
                                                    <div className='edit-box'>
                                                        <div className='edit' onClick={() => { this.editEduEXP(e) }}>
                                                            <img className='icon' src={require('./../../assets/edit-icon.png')} alt="" />
                                                            编辑
                                                        </div>
                                                        <div className='del' onClick={() => { this.deleteEduEXP(e) }}>
                                                            <img className='icon' src={require('./../../assets/del-icon.png')} alt="" />
                                                            删除
                                                        </div>
                                                    </div>
                                                    <Row>
                                                        <div className='character33500'>
                                                            {e.name}
                                                        </div>
                                                        <div className='character82'>
                                                            {common.dateFormat(e.start_time)}
                                                            -
                                                            {common.dateFormat(e.end_time)}
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className='divide-box'>
                                                            <div className='character33500'>
                                                                {e.profession}&nbsp;&nbsp;&nbsp;&nbsp;{e.education_name.replace("|education", "")}
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                        }
                    </div>
                </Row>
                <Modal title="教育经历" visible={this.state.imformationModalVisible5}
                    confirmLoading={this.state.confirmLoading}
                    onOk={this.imformationModalOK5.bind(this)} onCancel={this.imformationModalcancle5}
                    destroyOnClose
                >
                    <Form name='imformation' ref={this.formRef5}>
                        <FormItem label="学校" >
                            <Input placeholder='学校名称' defaultValue={this.state.eduEXP.name==""?null:this.state.eduEXP.name} onChange={e => { var value = e.target.value; this.setState(state => { state.eduEXP.name = value }) }} />
                        </FormItem>
                        <FormItem label="时间">
                            <RangePicker defaultValue={this.state.eduEXP.start_time==0?null:[moment(this.state.eduEXP.start_time),moment(this.state.eduEXP.end_time)]} onChange={this.onChange5.bind(this)} />
                        </FormItem>
                        <FormItem label="专业">
                            <Input placeholder='专业名称' defaultValue={this.state.eduEXP.profession==""?null:this.state.eduEXP.profession} onChange={e => { var value = e.target.value; this.setState(state => { state.eduEXP.profession = value }) }} />
                        </FormItem>
                        <FormItem label="学历" >
                            <Select style={{ width: 120 }} placeholder="请选择学历" onChange={this.handleChange3.bind(this)} 
                                defaultValue={this.state.eduEXP.education_name==""?null:this.state.eduEXP.education_name.replace("|education", "")}>
                                {
                                    this.state.allEducationList.map(e => (
                                        <Option key={e.name} value={e.id}>{e.name}</Option>
                                    ))
                                }
                            </Select>
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        )
    }
}
