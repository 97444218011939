import React from 'react';
import ReactDom from 'react-dom';
import './index.css';
import App from './App';
import "antd/dist/antd.css";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

ReactDom.render(
  <ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
);
