import React, { Component } from 'react'
import { Col, Modal, Row, Upload, Input, Button, Avatar, message, Form, Select, DatePicker, InputNumber } from 'antd'
import http from '../../http'
import FormItem from 'antd/lib/form/FormItem'
import common from '../../public/base'
import moment from 'moment'

const { RangePicker } = DatePicker;

const { TextArea } = Input;
export default class projectEXP extends Component {
    constructor(props) {
        super(props)
        this.state = {
            confirmLoading: false,
            imformationModalVisible4: false,
            projectList: [],
            projectEXP: {
                id: "",
                name: "",
                description: "",
                start_time: 0,
                end_time: 0
            },
        }
        this.getProjectExperience()
    }

    getProjectExperience() {
        http.getProjectExperienceList().then((res) => {
            this.setState({
                projectList: res.data
            })
        })
    }

    showInformationModal4 = () => {
        this.setState({
            imformationModalVisible4: true,
        })
    }

    imformationModalOK4() {
        this.setState({
            confirmLoading: true
        })
        var params = {}
        var flag = true
        if (this.state.projectEXP.name != "") {
            params.name = this.state.projectEXP.name
        } else {
            message.info("请填写项目名称")
            flag = false
        }
        if (this.state.projectEXP.description != "") {
            params.description = this.state.projectEXP.description
        } else {
            message.info("请填写描述名称")
            flag = false
        }
        if (this.state.projectEXP.start_time != 0) {
            params.start_time = this.state.projectEXP.start_time
        } else {
            message.info("请填写项目开始时间")
            flag = false
        }
        if (this.state.projectEXP.end_time != 0) {
            params.end_time = this.state.projectEXP.end_time
        } else {
            message.info("请填写项目结束时间")
            flag = false
        }
        if (flag) {
            if (this.state.projectEXP.id == "") {
                http.addProjectExperienceList(params).then((res) => {
                    if (res.result == 1) {
                        message.success("修改成功")
                        this.setState(state => {
                            state.imformationModalVisible4 = false
                            state.confirmLoading = false
                            state.projectEXP.id = ""
                            state.projectEXP.name = ""
                            state.projectEXP.description = ""
                            state.projectEXP.start_time = 0
                            state.projectEXP.end_time = 0
                        })
                        this.getProjectExperience()
                        this.formRef4.current.resetFields()
                    } else {
                        message.warn("修改失败")
                        this.setState(state => {
                            state.imformationModalVisible4 = false
                            state.confirmLoading = false
                            state.projectEXP.id = ""
                            state.projectEXP.name = ""
                            state.projectEXP.description = ""
                            state.projectEXP.start_time = 0
                            state.projectEXP.end_time = 0
                        })
                        this.formRef4.current.resetFields()
                    }
                })
            } else {
                var id = this.state.projectEXP.id
                http.updataProjectExperiencenList(id, params).then((res) => {
                    if (res.result == 1) {
                        message.success("修改成功")
                        this.setState(state => {
                            state.imformationModalVisible4 = false
                            state.confirmLoading = false
                            state.projectEXP.id = ""
                            state.projectEXP.name = ""
                            state.projectEXP.description = ""
                            state.projectEXP.start_time = 0
                            state.projectEXP.end_time = 0
                        })
                        this.getProjectExperience()
                        this.formRef4.current.resetFields()
                    } else {
                        message.warn("修改失败")
                        this.setState(state => {
                            state.imformationModalVisible4 = false
                            state.confirmLoading = false
                            state.projectEXP.id = ""
                            state.projectEXP.name = ""
                            state.projectEXP.description = ""
                            state.projectEXP.start_time = 0
                            state.projectEXP.end_time = 0
                        })
                        this.formRef4.current.resetFields()
                    }
                })
            }
        } else {
            this.setState({
                confirmLoading: false
            })
        }
    }

    editProjectEXP(e) {
        this.setState({
            projectEXP: e
        }, () => { this.showInformationModal4() })
    }

    deleteProjectEXP(e) {
        http.deleteProjectExperiencenList(e.id).then((res) => {
            if (res.result == 1) {
                message.success("删除成功")
                this.getProjectExperience()
            } else {
                message.warn("删除失败")
            }
        })
    }

    imformationModalcancle4 = () => {
        this.setState({
            imformationModalVisible4: false,
            confirmLoading: false
        })
        this.setState(state => {
            state.projectEXP.id = ""
            state.projectEXP.name = ""
            state.projectEXP.description = ""
            state.projectEXP.start_time = 0
            state.projectEXP.end_time = 0
        })
        this.getProjectExperience()
    }

    onChange4(date1) {
        this.setState(state => {
            state.projectEXP.start_time = moment(date1[0]).valueOf()
            state.projectEXP.end_time = moment(date1[1]).valueOf()
        })
    }
    render() {
        return (
            <div>
                <Row className='title-head' justify='space-between'>
                    <Col>
                        <Row>
                            <div className='title-logo'>

                            </div>
                            <div className='title-character'>
                                项目经历
                            </div>
                        </Row>
                    </Col>
                    <Col>
                        <div id='ignore5' className='edit' onClick={this.showInformationModal4}>
                            <img className='icon' src={require('./../../assets/add-icon.png')} alt="" />
                            添加
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className='item-box'>
                        {
                            this.state.projectList == null ?
                                (
                                    <div></div>
                                )
                                :
                                (
                                    this.state.projectList.map((e) => {
                                        return (
                                            <div className='edit-box' key={e.id}>
                                                <div className='item'>
                                                    <div className='edit-box'>
                                                        <div className='edit' onClick={() => this.editProjectEXP(e)}>
                                                            <img className='icon' src={require('./../../assets/edit-icon.png')} alt="" />
                                                            编辑
                                                        </div>
                                                        <div className='del' onClick={() => this.deleteProjectEXP(e)}>
                                                            <img className='icon' src={require('./../../assets/del-icon.png')} alt="" />
                                                            删除
                                                        </div>
                                                    </div>
                                                    <Row>
                                                        <div className='character33500'>
                                                            {e.name}
                                                        </div>
                                                        <div className='character82'>
                                                            {common.dateFormat(e.start_time)}
                                                            -
                                                            {common.dateFormat(e.end_time)}
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className='divide-box'>
                                                            <div className='character33500'>
                                                                {e.position}
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className='character33'>
                                                            {e.description}
                                                        </div>
                                                    </Row>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                        }
                    </div>
                </Row>
                <Modal title="项目经历" visible={this.state.imformationModalVisible4}
                    confirmLoading={this.state.confirmLoading}
                    onOk={this.imformationModalOK4.bind(this)} onCancel={this.imformationModalcancle4}
                    destroyOnClose
                    >
                    <Form name='imformation' ref={this.formRef4}>
                        <FormItem label="项目名称" >
                            <Input placeholder='项目名称' defaultValue={this.state.projectEXP.name==""?null:this.state.projectEXP.name} onChange={e => { var value = e.target.value; this.setState(state => { state.projectEXP.name = value }) }} />
                        </FormItem>
                        <FormItem label="项目时间" >
                            <RangePicker defaultValue={this.state.projectEXP.start_time==0?null:[moment(this.state.projectEXP.start_time),moment(this.state.projectEXP.end_time)]} onChange={this.onChange4.bind(this)} />
                        </FormItem>
                        <FormItem label="项目描述">
                            <TextArea rows={6} style={{ resize: 'none' }} placeholder='项目描述' defaultValue={this.state.projectEXP.description==""?null:this.state.projectEXP.description} onChange={e => { var value = e.target.value; this.setState(state => { state.projectEXP.description = value }) }} />
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        )
    }
}
