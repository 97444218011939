import { Row } from 'antd'
import React, { Component } from 'react'
import './loginGuideBox.less'

export default class loginGuideBox extends Component {
  render() {
    return (
      <div className='login-guide-box'>
          <div className='guide-detail'>
              <Row>
                  <div className='first-step'>
                    1
                  </div>
                  <div className='guide-text'>
                    登录/注册机器人人才网
                  </div>
              </Row>
              <Row>
                <div className='divide'>
                    |
                </div>
              </Row>
              <Row>
                  <div className='step'>
                    2
                  </div>
                  <div className='guide-text'>
                    完善个人简历信息
                  </div>
              </Row>
              <Row>
                <div className='divide'>
                    |
                </div>
              </Row>
              <Row>
                <div className='step'>
                  3
                </div>
                <div className='guide-text'>
                  投递简历
                </div>
              </Row>
              <Row>
                <div className='button'>
                  立即登录/注册
                </div>
              </Row>
          </div>
      </div>
    )
  }
}
