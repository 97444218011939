import React, {Component} from 'react'
import {Row, Col} from 'antd'
import './footer.less'

export default class footer extends Component {
	render() {
		return (
			<div className="footer">
				<Row className='footer-row' align="bottom">
					<Col span={14}>
						<p className='title'>关于机器人人才网</p>
						<p>机器人人才网隶属于杭州指南车机器人科技有限公司，专注于打造工业自动化系统集成领域专门的精准招聘就业平台。机器人人才网秉承为
							“中国智能制造2025”提供强大的后备力量，为企业输送高端人才，为行业提供优质高效的人才推荐服务，解决就业难就业不精准等问题， 推动自动化制造业行业发展，为祖国工业化的发展贡献自己的力量！</p>
					</Col>
					<Col offset={3} span={2.5}>
						<img className='wx-code' src={require('./../../assets/wx-code.png')} alt=""/>
					</Col>
					<Col span={5.5} className='phone'>
						<p>微信扫一扫左侧二维码<br/>添加机器人人才网客服微信</p>
						<p style={{marginTop: '8px'}}>或拨打合作热线: <span>4008616755</span></p>
					</Col>
				</Row>
				<div className="url-link">
					<li>
						<a target="_blank" href="http://www.zhinanche.com/">指南车首页
						</a>
					</li>
					<div className="line"></div>
					<li>
						<a target="_blank" href="http://school.zhinanche.com">工程师教育</a>
					</li>
					<div className="line"></div>
					<li>
						<a target="_blank" href="http://www.zhinanche.online/">在线教育</a>
					</li>
					<div className="line"></div>
					<li>
						<a target="_blank" href="http://service.zhinanche.com">技术服务</a>
					</li>
					<div className="line"></div>
					<li>
						<a target="_blank" href="http://cert.zhinanche.com">指南车认证</a>
					</li>
					<div className="line"></div>
					<li>
						<a target="_blank" href="http://www.zhinanche.com/src/common/main.html">关于我们</a>
					</li>
				</div>
				<div className="ba">
					<p>©️Copyright 杭州指南车机器人科技有限公司 I 浙ICP备15025120号</p>
				</div>
			</div>
		)
	}
}
