import {Row} from 'antd'
import Item from 'antd/lib/list/Item'
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import './hotCompanyCard.less'
import common from '../../public/base'

export default class hotCompanyCard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			item: props.item
		}
	}

	goDetailCompany() {
		const w = window.open('about:blank');
		w.location.href = common.CompanyURL + this.props.item.id;
	}

	render() {
		let a = '/companyDetail?company=' + this.props.item.id
		let item = this.props.item
		return (
			<div className='hotCompanyCard' key={Item.id} onClick={() => {
				this.goDetailCompany()
			}}>
				<div className=''>
					<Row>
						<div className='hcc-company-logo'>
							<img src={item.logo}></img>
						</div>

						<div className='hcc-company-detail'>
							<div className='job'>
								{item.name}
							</div>
							<div className='box'></div>
							<div className='detail'>
								{item.scale.name}&nbsp;
								|&nbsp;{item.address.city}&nbsp;
								|&nbsp;{item.industry.map((e, index) => {
								if (index === 0) {
									return e.name
								} else {
									return "、" + e.name
								}
							})}
							</div>
							<div className='number'>在招职位:{item.position.length}个</div>
							<div style={{width: '196px', height:'1px', backgroundColor: '#f2f2f2', marginTop: '16px'}}/>
						</div>
					</Row>
				</div>
			</div>
		)
	}
}
