import {Col, message, Row} from 'antd'
import React, {Component} from 'react'
import './jobCard.less'
import common from '../../public/base'

export default class jobCard extends Component {
	constructor(props) {
		super(props)
	}

	goDetail() {
		// this.jobLook()
		if (!localStorage.getItem('token')) {
			message.warn("请先登录")
		} else {
			const w = window.open('about:blank');
			w.location.href = common.JobURL + this.props.job.id;
		}
	}

	render() {
		var job = this.props.job
		return (
			<div className='message-box' onClick={this.goDetail.bind(this)}>
				<Row justify='space-between' className='job-detail'>
					<Col className='job'>
						{job.name}
					</Col>
					<Col className='salary'>
						{job.salary.name.replace('"', '')}
					</Col>
				</Row>
				<Row>
					<div className='detail'>
						{job.address.city.replace(/"/g, '')}&nbsp;&nbsp;|&nbsp;&nbsp;{job.work_years.name.replace('"', '')}&nbsp;&nbsp;|&nbsp;&nbsp;{job.education.name.replace('"', '')}
					</div>
				</Row>
				<Row>
					<div className='welfare-box'>
						{
							job.welfare.map((e, index) => {
								if (index < 4) {
									return (
										<div className='welfare' key={e.id}>
											{e.name.replace('"', '')}
										</div>
									)
								}
							})
						}
					</div>
				</Row>
				<Row>
					<div className='divider'></div>
				</Row>
				<Row>
					<div className='company-box'>
						<div className='company-logo'>
							<img src={job.company.logo}/>
						</div>
						<div className='company-detail'>
							<div className='job'>
								{job.company.name}
							</div>
							<div className='box'></div>
							<div className='detail'>
								{job.company.scale.name}&nbsp;&nbsp;|&nbsp;&nbsp;{job.company.address.city.replace(/"/g, '')}
							</div>
						</div>
					</div>
				</Row>
			</div>
		)
	}
}
